import axios from 'axios'

class APIProcessi {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;

  }

  getUrlDomainNotApi(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  getUrlDomainGo(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = "http://localhost:4012/";
    }

    if (location.hostname != "localhost") {

      result = "https://filestransfer.it:4012/";

    }


    return result + nome_metodo;

  }


  async setProcesso(processo, id, user) {


    let formData = new FormData();

    formData.append("v_id", id);
    formData.append("v_user", user);

    for (var key in processo) {
      formData.append(key, processo[key]);
    }

    return axios.post(this.getUrlDomain("SchedToolSaveProcesso"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async setProcessoGo(processo, id, user) {


    let formData = new FormData();

    formData.append("v_id", id);
    formData.append("v_user", user);

    for (var key in processo) {
      formData.append(key, processo[key]);
    }

    return axios.post(this.getUrlDomainGo("saveProcesso"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getProcesso(id, user) {


    let formData = new FormData();

    formData.append("v_id", id);
    formData.append("v_user", user);


    return axios.post(this.getUrlDomain("SchedToolEditProcesso"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getProcessoGo(id, user) {


    let formData = new FormData();

    formData.append("v_id", id);
    formData.append("v_user", user);


    return axios.post(this.getUrlDomainGo("editProcesso"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async deleteProcesso(id, user) {


    let formData = new FormData();

    formData.append("v_id", id);
    formData.append("v_user", user);


    return axios.post(this.getUrlDomain("SchedToolDeleteProcesso"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async deleteMultiPagamento(id, user) {


    let formData = new FormData();

    formData.append("id", id);
    formData.append("v_user", user);


    return axios.post(this.getUrlDomain("deleteMultiPagamento"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async getTipologie(user) {

    let formData = new FormData();

    formData.append("v_user", user);

    return axios.post(this.getUrlDomain("getTipologie"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  sendNotify(pagamento, user, id) {

    console.log("SEND NOTIFY PAG: ", pagamento);

    let formData = new FormData();

    formData.append("id", id);
    formData.append("v_user", user);

    for (var key in pagamento) {
      formData.append(key, pagamento[key]);
    }

    return axios.post(this.getUrlDomain("sendNotify"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getAziende(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomainNotApi("getAziende"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getFilters(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomainNotApi("getAlertDueDateFilters"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async initFilters(user) {


    let formData = new FormData();

    formData.append("v_user", user);

    return axios.post(this.getUrlDomain("initFiltersPagamentiAlertDueDate"), formData, { 'Content-Type': 'multipart/form-data' });

  }




  async initInsert(user) {


    let formData = new FormData();

    formData.append("v_user", user);


    return axios.post(this.getUrlDomain("SchedToolInitInsertProcesso"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async initInsertGo(user) {


    let formData = new FormData();

    formData.append("v_user", user);


    return axios.post(this.getUrlDomainGo("initInsertProcesso"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async initFilter(user) {


    let formData = new FormData();

    formData.append("v_user", user);


    return axios.post(this.getUrlDomain("SchedToolInitFilterProcesso"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async checkConnGDrive(user) {


    let formData = new FormData();

    formData.append("v_user", user);


    return axios.post(this.getUrlDomain("SchedToolChkGDriveConn"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async runProcesses(id) {


    let formData = new FormData();

    formData.append("v_id", id);


    return axios.post(this.getUrlDomain("SchedToolRunProcessi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async stopProcesses(id) {


    let formData = new FormData();

    formData.append("v_id", id);


    return axios.post(this.getUrlDomain("SchedToolStopProcessi"), formData, { 'Content-Type': 'multipart/form-data' });

  }





  async checkRunning(id) {


    let formData = new FormData();

    formData.append("v_id", id);


    return axios.post(this.getUrlDomain("SchedToolCheckRunProcessi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async changeState(id, val_stato) {


    let formData = new FormData();

    formData.append("v_id", id);
    formData.append("val_stato", val_stato);


    return axios.post(this.getUrlDomain("SchedToolChangeStateProcessi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async historyBackup(code_processo) {


    let formData = new FormData();

    formData.append("code_processo", code_processo);

    return axios.post(this.getUrlDomain("SchedToolHistoryBackup"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async infoConfigurazione(id) {


    let formData = new FormData();

    formData.append("v_id", id);

    return axios.post(this.getUrlDomain("SchedToolInfoConfProcesso"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async infoConfigurazioneGo(id) {


    let formData = new FormData();

    formData.append("v_id", id);

    return axios.post(this.getUrlDomainGo("getInfoProcesso"), formData, { 'Content-Type': 'multipart/form-data' });

  }


}

export default new APIProcessi({
  url: "http://emtool.local/public/api/alertduedate/"
})
