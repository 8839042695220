<template>
    <div class="cont-table">

        <div class="spin">
            <ion-spinner name="crescent"></ion-spinner>
        </div>


        <div class="row cont_schedtool_processi cont_tickets">
            <div class=" " style="margin:0 auto;">
                <div class="card">

                    <div class="card-body" style="padding:0px">

                        <div class="table-responsive" style="">

                            <table id="myTable" style="margin-top:59px">
                                <thead>
                                    <tr>


                                        <th class='all'>
                                            <div class="cl_checkall">
                                                <ion-checkbox name='emailgestoreall'
                                                    style="width:10px!important;height:10px!important" class='checkall'
                                                    slot='start'>
                                                </ion-checkbox>
                                            </div>
                                        </th>



                                        <th class='all'>
                                            <div class='cl_num'>N.</div>
                                        </th>

                                        <th class='all'>Tipologia</th>
                                        <th class='all'>Descrizione</th>
                                        <th class='all' style="text-align: center;">Abil.</th>
                                        <th class='all' style="text-align: center;">Esec.</th>
                                        <th class='all'>Data/H Inizio</th>
                                        <th class='desktop'>Data/H Fine</th>
                                        <th class='desktop' style="text-align:center">Mail</th>
                                        <th class='desktop'>Dalle</th>
                                        <th class='desktop'>Alle</th>
                                        <th class='desktop' style="text-align:center">Freq. (MM)</th>

                                    </tr>
                                </thead>

                                <tfoot>

                                    <tr>
                                        <th colspan="12">
                                        </th>
                                    </tr>
                                </tfoot>

                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="contpopupsearch" style="display:none">

            <template>
                <modal name="popupSearch" :clickToClose="false" :width="262" :height="253">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Filtri

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="textSearch" label="Cerca testo" id="fldSearch">
                            </v-text-field>

                        </v-col>

                    </v-row>

                    <v-row
                        style="margin-bottom: 0px!important;padding-bottom: 0px!important;margin-top:0px!important;padding-top: 0px!important">

                        <v-col cols="12" md="12"
                            style="margin-bottom: 0px!important;padding-bottom: 0px!important;margin-top:0px!important;padding-top: 0px!important">

                            <div id="cont_anno" style="cursor:pointer">
                                <ion-item id="itemAnno"
                                    style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                    <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                        position="floating">Tipologia </ion-label>

                                    <ion-select id="tipologia" name="tipologia" class='tipologia' cancel-Text="Chiudi"
                                        done-Text="" :value="filterTipologia" interface="action-sheet">

                                        <ion-select-option v-for="item in tipologie" :key="item.id" :value='item.id'>
                                            {{
                                                item.nome }}</ion-select-option>

                                    </ion-select>
                                </ion-item>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain
                                    src="@/assets/btn_cancel3_alertduedate.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Annulla" @click="btnCancelSearch" />

                                <v-img alt="" class="btn-delete-search" contain src="@/assets/reset-schedtool.png"
                                    max-width="35" max-height="35"
                                    style="cursor:pointer;color:black;margin-right:10px;margin-top: -2px;"
                                    transition="scale-transition" title="Elimina Filtri" @click="btnResetSearch" />

                                <v-img alt="" class="btn-confirm-search" contain
                                    src="@/assets/btn_confirm3_alertduedate.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Conferma"
                                    @click="btnConfirmSearch" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



        <div class="conttipologie">

            <template>
                <div class="text-center">

                    <v-bottom-sheet v-model="sheetTipologie">
                        <v-sheet class="text-center" height="430px" style="padding-bottom:100px;">

                            <div style="text-align:right!important">

                                <v-btn class='closeTipologieSheet' icon @click="sheetTipologie = !sheetTipologie">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                            </div>

                            <div class="py-3 c_title_stati">
                                Tipologie
                            </div>

                            <v-list-item v-for="tile in tipologie" :key="tile.id" @click="filterTipologia(tile.id)">

                                <v-list-item-title style="text-align:left!important;font-size:9px!important">{{
                                    tile.nome
                                }}</v-list-item-title>

                            </v-list-item>

                        </v-sheet>
                    </v-bottom-sheet>

                </div>
            </template>

        </div>



        <div class="contpopuphistorybackup" style="display:none">

            <template>
                <modal name="popupHistoryBackup" :clickToClose="false" :width="367" :height="223">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Storico Backup

                        </v-col>

                    </v-row>

                    <v-row>
                        <v-col cols="12" md="12" style="margin-top: 0px;padding-top: 0px;display:flex">
                            <div style="font-weight: bold;margin-left: 10px;width:232px">File</div>
                            <div style="font-weight: bold;">Data</div>
                        </v-col>
                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top: 0px;padding-top: 0px;">

                            <v-list
                                style="overflow-y:auto;max-height: 100px;min-height: 100px;margin-top: 0px;padding-top: 0px;">

                                <v-list-tile v-for="el in files_backup" :key="el.id" style="display: flex;">


                                    <div class="styleFileNameHistory">{{ el.nome }}</div>

                                    <div class="styleDateTimeHistory">{{ el.datetime }}</div>

                                </v-list-tile>



                            </v-list>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-history" contain
                                    src="@/assets/btn_cancel3_alertduedate.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Annulla" @click="btnCancelHistoryBackup" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



        <div class="contpopupinfoconf" style="display:none">

            <template>
                <modal name="popupInfoConf" :clickToClose="false" :width="343" :height="788">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Configurazione

                        </v-col>

                    </v-row>

                    <v-row
                        style="margin-top:4px!important; padding-top:0px!important;margin-bottom:0px!important;padding-bottom:0px!important">

                        <v-col cols="12" md="12"
                            style="margin-top:10px!important;font-weight:bold;margin-left:10px; margin-top:0px!important; padding-top:0px!important;margin-bottom:0px!important; padding-bottom:0px!important">

                            {{ titlePopup }}

                        </v-col>

                    </v-row>


                    <v-row style="margin-left:1px!important" v-if="showRow4">

                        <v-col cols="6" md="6">
                            Fatture Scadute
                        </v-col>

                    </v-row>

                    <v-row style="margin-left:1px!important" v-if="showRow3">

                        <v-col cols="6" md="6">

                            <label style="font-size:11px">Giorni Alla Scadenza</label>

                            <div id="d_fido_ant_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="impostazioni_invio_mail.days_before_alert"
                                                :options="'integer'" ref="fldDaysLeft"
                                                id="fldDaysLeft"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>


                    <v-row style="margin-left:1px!important;margin-top:4px!important;padding-top:0px!important"
                        v-if="showRow12">

                        <v-col cols="6" md="6" style="margin-top:0px!important;padding-top:0px!important">

                            <label style="font-size:11px">Giorni A Fine Mese</label>

                            <div id="d_fido_ant_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="impostazioni_invio_mail.days_before_alert"
                                                :options="'integer'" ref="fldDaysLeft"
                                                id="fldDaysLeft"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>


                    <v-row style="margin-left:1px!important" v-if="showRow">

                        <v-col cols="3" md="3">

                            <label class="v-label v-label--active theme--light"
                                style="margin-top:12px!important">Dalle</label>
                            <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                                style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                                id="dalle" name="dalle" class='hourbooking' cancel-Text="" done-Text=""
                                display-format="HH:mm">
                            </ion-datetime>

                        </v-col>

                        <v-col cols="3" md="3">

                            <label class="v-label v-label--active theme--light"
                                style="margin-top:12px!important">Alle</label>
                            <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                                style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                                id="alle" name="alle" class='hourbooking' cancel-Text="" done-Text=""
                                display-format="HH:mm">
                            </ion-datetime>

                        </v-col>

                        <v-col cols="6" md="6">

                            <div id="cont_minutifreq" style="cursor:pointer">
                                <ion-item id="itemFreq"
                                    style="min-width:190px!important;transform: translateX(-6px);margin-top:-8px;margin-bottom:18px">

                                    <ion-label style="font-size:11px;cursor:pointer;opacity:1" position="floating">Freq.
                                        (MM)
                                    </ion-label>

                                    <ion-select id="minfreq" name="minfreq" class='minfreq' cancel-Text="Chiudi"
                                        done-Text="" :value="impostazioni_invio_mail.mail_minutes_tot"
                                        interface="action-sheet">

                                        <ion-select-option v-for="item in minuti_frequenza" :key="item.valore"
                                            :value='item.valore'> {{ item.descrizione }}</ion-select-option>

                                    </ion-select>
                                </ion-item>
                            </div>

                        </v-col>


                    </v-row>


                    <v-row style="margin-left:-8px!important" v-if="showRow">

                        <v-col cols="2" md="2" style="min-width: 70px;">

                            <label style="margin-left:11px">Lun</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_monday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Mar</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_tuesday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Mer</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_wednesday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Gio</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_thursday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Ven</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_friday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Sab</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_saturday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:8px">Dom</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_sunday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                    </v-row>

                    <v-row style="margin-left:1px!important" v-if="showRow2">

                        <v-col cols="6" md="6">

                            <label>Numero files rimanenti nella folder</label>

                            <div id="d_fido_ant_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="impostazioni_invio_mail.num_files_left"
                                                :options="'integer'" ref="fldNumFilesLeft"
                                                id="fldNumFilesLeft"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>



                    <v-row style="margin-left:1px!important" v-if="showRow5">

                        <v-col cols="6" md="6">
                            Fatture In Scadenza
                        </v-col>

                    </v-row>

                    <v-row style="margin-left:1px!important" v-if="showRow6">

                        <v-col cols="6" md="6">

                            <label style="font-size:11px">Giorni Alla Scadenza</label>

                            <div id="d_fido_ant_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="impostazioni_invio_mail.days_before_alert"
                                                :options="'integer'" ref="fldDaysLeft"
                                                id="fldDaysLeft"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>


                    <v-row style="margin-left:1px!important" v-if="showRow7">

                        <v-col cols="3" md="3">

                            <label class="v-label v-label--active theme--light"
                                style="margin-top:12px!important">Dalle</label>
                            <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                                style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                                id="dalleExpiring" name="dalleExpiring" class='hourbooking' cancel-Text="" done-Text=""
                                display-format="HH:mm">
                            </ion-datetime>

                        </v-col>

                        <v-col cols="3" md="3">

                            <label class="v-label v-label--active theme--light"
                                style="margin-top:12px!important">Alle</label>
                            <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                                style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                                id="alleExpiring" name="alleExpiring" class='hourbooking' cancel-Text="" done-Text=""
                                display-format="HH:mm">
                            </ion-datetime>

                        </v-col>

                        <v-col cols="6" md="6">

                            <label class="v-label v-label--active theme--light" style="margin-top:12px!important">Freq.
                                (H)</label>

                            <ion-datetime hour-cycle="h12" name="tipo" id="selfrequenzaexpiring"
                                class='selfrequenzaexpiring' display-format="H" cancel-Text="" done-Text="" value=""
                                interface="action-sheet"
                                style="margin-top:12px!important;border-bottom: 1px solid rgb(206, 212, 218);height:21px!important;max-width:90px!important"
                                min="2022-03-29T01:21:23.654+02:00">

                            </ion-datetime>

                        </v-col>

                    </v-row>

                    <v-row style="margin-left:-8px!important" v-if="showRow8">

                        <v-col cols="2" md="2">

                            <label style="margin-left:11px">Lun</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_monday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Mar</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_tuesday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Mer</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_wednesday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Gio</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_thursday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Ven</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_friday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Sab</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_saturday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:8px">Dom</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_sunday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                    </v-row>


                    <v-row style="margin-left:1px!important" v-if="showRow10">

                        <v-col cols="3" md="3">

                            <label class="v-label v-label--active theme--light"
                                style="margin-top:12px!important">Dalle</label>
                            <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                                style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                                id="dalle" name="dalle" class='hourbooking' cancel-Text="" done-Text=""
                                display-format="HH:mm">
                            </ion-datetime>

                        </v-col>

                        <v-col cols="3" md="3">

                            <label class="v-label v-label--active theme--light"
                                style="margin-top:12px!important">Alle</label>
                            <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                                style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                                id="alle" name="alle" class='hourbooking' cancel-Text="" done-Text=""
                                display-format="HH:mm">
                            </ion-datetime>

                        </v-col>

                        <v-col cols="3" md="3">

                            <div id="cont_minutifreq" style="cursor:pointer">
                                <ion-item id="itemFreq"
                                    style="min-width:190px!important;transform: translateX(-6px);margin-top:-8px;margin-bottom:18px">

                                    <ion-label style="font-size:11px;cursor:pointer;opacity:1" position="floating">Freq.
                                        (MM)
                                    </ion-label>

                                    <ion-select id="minfreq" name="minfreq" class='minfreq' cancel-Text="Chiudi"
                                        done-Text="" :value="impostazioni_invio_mail.mail_minutes_tot"
                                        interface="action-sheet">

                                        <ion-select-option v-for="item in minuti_frequenza" :key="item.valore"
                                            :value='item.valore'> {{ item.descrizione }}</ion-select-option>

                                    </ion-select>
                                </ion-item>
                            </div>

                        </v-col>

                    </v-row>



                    <v-row style="margin-left:-8px!important" v-if="showRow11">

                        <v-col cols="2" md="2" style="min-width: 70px!important;">

                            <label style="margin-left:11px">Lun</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_monday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Mar</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_tuesday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Mer</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_wednesday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Gio</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_thursday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Ven</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_friday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Sab</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_saturday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:8px">Dom</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_sunday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                    </v-row>


                    <v-row v-if="showRowTitle">

                        <v-col cols="9" md="9">

                            <v-text-field v-model="impostazioni_invio_mail.titolo" label="Titolo"
                                id="fldTitolo"></v-text-field>

                        </v-col>

                    </v-row>

                    <v-row v-if="showRowDesc">

                        <v-col cols="9" md="9">

                            <v-textarea label="Descrizione" v-model="impostazioni_invio_mail.descrizione"
                                id="fldDescrizione"></v-textarea>

                        </v-col>

                    </v-row>





                    <v-row v-if="showRowMail"
                        style="margin-top:0px!important;margin-bottom:0px!important;padding-top:0px!important;padding-bottom:0px!important">

                        <v-col cols="12" md="12"
                            style="margin-top:0px!important;margin-bottom:0px!important;padding-top:0px!important;padding-bottom:0px!important">
                            <v-checkbox label="Invio Mail" v-model="impostazioni_invio_mail.send_mail" true-value="S"
                                false-value="N" id="chkMinutes" class="cl-checkbox" @click="chkChange"></v-checkbox>
                        </v-col>

                    </v-row>




                    <v-row style="margin-top:0px!important;padding-top:0px!important">

                        <v-col cols="12" md="12" style="margin-top:0px!important;padding-top:0px!important">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-conf" contain
                                    src="@/assets/btn_cancel3_alertduedate.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Annulla" @click="btnCancelInfoConf" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupinfoconf2" style="display:none">

            <template>
                <modal name="popupInfoConf2" :clickToClose="false" :width="343" :height="560">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Configurazione

                        </v-col>

                    </v-row>

                    <v-row
                        style="margin-top:4px!important; padding-top:0px!important;margin-bottom:0px!important;padding-bottom:0px!important">

                        <v-col cols="12" md="12"
                            style="margin-top:10px!important;font-weight:bold;margin-left:10px; margin-top:0px!important; padding-top:0px!important;margin-bottom:0px!important; padding-bottom:0px!important">

                            {{ titlePopup }}

                        </v-col>

                    </v-row>

                    <v-row style="margin-left:1px!important" v-if="showRow4">

                        <v-col cols="6" md="6">
                            Fatture Scadute
                        </v-col>

                    </v-row>

                    <v-row style="margin-left:1px!important" v-if="showRow3">

                        <v-col cols="6" md="6">

                            <label style="font-size:11px">Giorni Alla Scadenza</label>

                            <div id="d_fido_ant_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="impostazioni_invio_mail.days_before_alert"
                                                :options="'integer'" ref="fldDaysLeft"
                                                id="fldDaysLeft"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>


                    <v-row style="margin-left:1px!important" v-if="showRow12">

                        <v-col cols="6" md="6">

                            <label style="font-size:11px">Giorni A Fine Mese</label>

                            <div id="d_fido_ant_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="impostazioni_invio_mail.days_before_alert"
                                                :options="'integer'" ref="fldDaysLeft"
                                                id="fldDaysLeft"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>


                    <v-row style="margin-left:1px!important" v-if="showRow">

                        <v-col cols="3" md="3">

                            <label class="v-label v-label--active theme--light"
                                style="margin-top:12px!important">Dalle</label>
                            <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                                style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                                id="dalle" name="dalle" class='hourbooking' cancel-Text="" done-Text=""
                                display-format="HH:mm">
                            </ion-datetime>

                        </v-col>

                        <v-col cols="3" md="3">

                            <label class="v-label v-label--active theme--light"
                                style="margin-top:12px!important">Alle</label>
                            <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                                style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                                id="alle" name="alle" class='hourbooking' cancel-Text="" done-Text=""
                                display-format="HH:mm">
                            </ion-datetime>

                        </v-col>

                        <v-col cols="6" md="6">

                            <div id="cont_minutifreq" style="cursor:pointer">
                                <ion-item id="itemFreq"
                                    style="min-width:190px!important;transform: translateX(-6px);margin-top:-8px;margin-bottom:18px">

                                    <ion-label style="font-size:11px;cursor:pointer;opacity:1" position="floating">Freq.
                                        (MM)
                                    </ion-label>

                                    <ion-select id="minfreq" name="minfreq" class='minfreq' cancel-Text="Chiudi"
                                        done-Text="" :value="impostazioni_invio_mail.mail_minutes_tot"
                                        interface="action-sheet">

                                        <ion-select-option v-for="item in minuti_frequenza" :key="item.valore"
                                            :value='item.valore'> {{ item.descrizione }}</ion-select-option>

                                    </ion-select>
                                </ion-item>
                            </div>

                        </v-col>


                    </v-row>


                    <v-row style="margin-left:-8px!important" v-if="showRow">

                        <v-col cols="2" md="2" style="min-width: 70px;">

                            <label style="margin-left:11px">Lun</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_monday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Mar</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_tuesday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Mer</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_wednesday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Gio</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_thursday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Ven</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_friday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Sab</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_saturday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:8px">Dom</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_sunday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                    </v-row>

                    <v-row style="margin-left:1px!important" v-if="showRow2">

                        <v-col cols="6" md="6">

                            <label>Numero files rimanenti nella folder</label>

                            <div id="d_fido_ant_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="impostazioni_invio_mail.num_files_left"
                                                :options="'integer'" ref="fldNumFilesLeft"
                                                id="fldNumFilesLeft"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>



                    <v-row style="margin-left:1px!important" v-if="showRow5">

                        <v-col cols="6" md="6">
                            Fatture In Scadenza
                        </v-col>

                    </v-row>

                    <v-row style="margin-left:1px!important" v-if="showRow6">

                        <v-col cols="6" md="6">

                            <label style="font-size:11px">Giorni Alla Scadenza</label>

                            <div id="d_fido_ant_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="impostazioni_invio_mail.days_before_alert"
                                                :options="'integer'" ref="fldDaysLeft"
                                                id="fldDaysLeft"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>


                    <v-row style="margin-left:1px!important" v-if="showRow7">

                        <v-col cols="3" md="3">

                            <label class="v-label v-label--active theme--light"
                                style="margin-top:12px!important">Dalle</label>
                            <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                                style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                                id="dalleExpiring" name="dalleExpiring" class='hourbooking' cancel-Text="" done-Text=""
                                display-format="HH:mm">
                            </ion-datetime>

                        </v-col>

                        <v-col cols="3" md="3">

                            <label class="v-label v-label--active theme--light"
                                style="margin-top:12px!important">Alle</label>
                            <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                                style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                                id="alleExpiring" name="alleExpiring" class='hourbooking' cancel-Text="" done-Text=""
                                display-format="HH:mm">
                            </ion-datetime>

                        </v-col>

                        <v-col cols="6" md="6">

                            <label class="v-label v-label--active theme--light" style="margin-top:12px!important">Freq.
                                (H)</label>

                            <ion-datetime hour-cycle="h12" name="tipo" id="selfrequenzaexpiring"
                                class='selfrequenzaexpiring' display-format="H" cancel-Text="" done-Text="" value=""
                                interface="action-sheet"
                                style="margin-top:12px!important;border-bottom: 1px solid rgb(206, 212, 218);height:21px!important;max-width:90px!important"
                                min="2022-03-29T01:21:23.654+02:00">

                            </ion-datetime>

                        </v-col>

                    </v-row>

                    <v-row style="margin-left:-8px!important" v-if="showRow8">

                        <v-col cols="2" md="2">

                            <label style="margin-left:11px">Lun</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_monday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Mar</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_tuesday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Mer</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_wednesday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Gio</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_thursday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Ven</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_friday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Sab</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_saturday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:8px">Dom</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_sunday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                    </v-row>


                    <v-row style="margin-left:1px!important" v-if="showRow10">

                        <v-col cols="3" md="3">

                            <label class="v-label v-label--active theme--light"
                                style="margin-top:12px!important">Dalle</label>
                            <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                                style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                                id="dalle" name="dalle" class='hourbooking' cancel-Text="" done-Text=""
                                display-format="HH:mm">
                            </ion-datetime>

                        </v-col>

                        <v-col cols="3" md="3">

                            <label class="v-label v-label--active theme--light"
                                style="margin-top:12px!important">Alle</label>
                            <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                                style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                                id="alle" name="alle" class='hourbooking' cancel-Text="" done-Text=""
                                display-format="HH:mm">
                            </ion-datetime>

                        </v-col>

                        <v-col cols="3" md="3">

                            <div id="cont_minutifreq" style="cursor:pointer">
                                <ion-item id="itemFreq"
                                    style="min-width:190px!important;transform: translateX(-6px);margin-top:-8px;margin-bottom:18px">

                                    <ion-label style="font-size:11px;cursor:pointer;opacity:1" position="floating">Freq.
                                        (MM)
                                    </ion-label>

                                    <ion-select id="minfreq" name="minfreq" class='minfreq' cancel-Text="Chiudi"
                                        done-Text="" :value="impostazioni_invio_mail.mail_minutes_tot"
                                        interface="action-sheet">

                                        <ion-select-option v-for="item in minuti_frequenza" :key="item.valore"
                                            :value='item.valore'> {{ item.descrizione }}</ion-select-option>

                                    </ion-select>
                                </ion-item>
                            </div>

                        </v-col>

                    </v-row>



                    <v-row style="margin-left:-8px!important" v-if="showRow11">

                        <v-col cols="2" md="2" style="min-width: 70px!important;">

                            <label style="margin-left:11px">Lun</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_monday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Mar</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_tuesday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Mer</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_wednesday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Gio</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_thursday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Ven</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_friday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Sab</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_saturday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:8px">Dom</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_sunday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                    </v-row>


                    <v-row v-if="showRowTitle">

                        <v-col cols="9" md="9">

                            <v-text-field v-model="impostazioni_invio_mail.titolo" label="Titolo"
                                id="fldTitolo"></v-text-field>

                        </v-col>

                    </v-row>

                    <v-row v-if="showRowDesc">

                        <v-col cols="9" md="9">

                            <v-textarea label="Descrizione" v-model="impostazioni_invio_mail.descrizione"
                                id="fldDescrizione"></v-textarea>

                        </v-col>

                    </v-row>





                    <v-row v-if="showRowMail">

                        <v-col cols="12" md="12">
                            <v-checkbox label="Invio Mail" v-model="impostazioni_invio_mail.send_mail" true-value="S"
                                false-value="N" id="chkMinutes" class="cl-checkbox" @click="chkChange"></v-checkbox>
                        </v-col>

                    </v-row>




                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-conf" contain
                                    src="@/assets/btn_cancel3_alertduedate.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Annulla" @click="btnCancelInfoConf2" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contpopupinfoconf3" style="display:none">

            <template>
                <modal name="popupInfoConf3" :clickToClose="false" :width="343" :height="560">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Configurazione

                        </v-col>

                    </v-row>

                    <v-row
                        style="margin-top:4px!important; padding-top:0px!important;margin-bottom:0px!important;padding-bottom:0px!important">

                        <v-col cols="12" md="12"
                            style="margin-top:10px!important;font-weight:bold;margin-left:10px; margin-top:0px!important; padding-top:0px!important;margin-bottom:0px!important; padding-bottom:0px!important">

                            {{ titlePopup }}

                        </v-col>

                    </v-row>

                    <v-row style="margin-left:1px!important" v-if="showRow4">

                        <v-col cols="6" md="6">
                            Fatture Scadute
                        </v-col>

                    </v-row>

                    <v-row style="margin-left:1px!important" v-if="showRow3">

                        <v-col cols="6" md="6">

                            <label style="font-size:11px">Giorni Alla Scadenza</label>

                            <div id="d_fido_ant_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="impostazioni_invio_mail.days_before_alert"
                                                :options="'integer'" ref="fldDaysLeft"
                                                id="fldDaysLeft"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>


                    <v-row style="margin-left:1px!important" v-if="showRow12">

                        <v-col cols="6" md="6">

                            <label style="font-size:11px">Giorni A Fine Mese</label>

                            <div id="d_fido_ant_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="impostazioni_invio_mail.days_before_alert"
                                                :options="'integer'" ref="fldDaysLeft"
                                                id="fldDaysLeft"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>


                    <v-row style="margin-left:1px!important" v-if="showRow">

                        <v-col cols="3" md="3">

                            <label class="v-label v-label--active theme--light"
                                style="margin-top:12px!important">Dalle</label>
                            <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                                style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                                id="dalle" name="dalle" class='hourbooking' cancel-Text="" done-Text=""
                                display-format="HH:mm">
                            </ion-datetime>

                        </v-col>

                        <v-col cols="3" md="3">

                            <label class="v-label v-label--active theme--light"
                                style="margin-top:12px!important">Alle</label>
                            <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                                style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                                id="alle" name="alle" class='hourbooking' cancel-Text="" done-Text=""
                                display-format="HH:mm">
                            </ion-datetime>

                        </v-col>

                        <v-col cols="6" md="6">

                            <div id="cont_minutifreq" style="cursor:pointer">
                                <ion-item id="itemFreq"
                                    style="min-width:190px!important;transform: translateX(-6px);margin-top:-8px;margin-bottom:18px">

                                    <ion-label style="font-size:11px;cursor:pointer;opacity:1" position="floating">Freq.
                                        (MM)
                                    </ion-label>

                                    <ion-select id="minfreq" name="minfreq" class='minfreq' cancel-Text="Chiudi"
                                        done-Text="" :value="impostazioni_invio_mail.mail_minutes_tot"
                                        interface="action-sheet">

                                        <ion-select-option v-for="item in minuti_frequenza" :key="item.valore"
                                            :value='item.valore'> {{ item.descrizione }}</ion-select-option>

                                    </ion-select>
                                </ion-item>
                            </div>

                        </v-col>


                    </v-row>


                    <v-row style="margin-left:-8px!important" v-if="showRow">

                        <v-col cols="2" md="2" style="min-width: 70px;">

                            <label style="margin-left:11px">Lun</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_monday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Mar</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_tuesday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Mer</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_wednesday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Gio</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_thursday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Ven</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_friday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Sab</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_saturday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:8px">Dom</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_sunday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                    </v-row>

                    <v-row style="margin-left:1px!important" v-if="showRow2">

                        <v-col cols="6" md="6">

                            <label>Numero files rimanenti nella folder</label>

                            <div id="d_fido_ant_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="impostazioni_invio_mail.num_files_left"
                                                :options="'integer'" ref="fldNumFilesLeft"
                                                id="fldNumFilesLeft"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>



                    <v-row style="margin-left:1px!important" v-if="showRow5">

                        <v-col cols="6" md="6">
                            Fatture In Scadenza
                        </v-col>

                    </v-row>

                    <v-row style="margin-left:1px!important" v-if="showRow6">

                        <v-col cols="6" md="6">

                            <label style="font-size:11px">Giorni Alla Scadenza</label>

                            <div id="d_fido_ant_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="impostazioni_invio_mail.days_before_alert"
                                                :options="'integer'" ref="fldDaysLeft"
                                                id="fldDaysLeft"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>


                    <v-row style="margin-left:1px!important" v-if="showRow7">

                        <v-col cols="3" md="3">

                            <label class="v-label v-label--active theme--light"
                                style="margin-top:12px!important">Dalle</label>
                            <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                                style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                                id="dalleExpiring" name="dalleExpiring" class='hourbooking' cancel-Text="" done-Text=""
                                display-format="HH:mm">
                            </ion-datetime>

                        </v-col>

                        <v-col cols="3" md="3">

                            <label class="v-label v-label--active theme--light"
                                style="margin-top:12px!important">Alle</label>
                            <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                                style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                                id="alleExpiring" name="alleExpiring" class='hourbooking' cancel-Text="" done-Text=""
                                display-format="HH:mm">
                            </ion-datetime>

                        </v-col>

                        <v-col cols="6" md="6">

                            <label class="v-label v-label--active theme--light" style="margin-top:12px!important">Freq.
                                (H)</label>

                            <ion-datetime hour-cycle="h12" name="tipo" id="selfrequenzaexpiring"
                                class='selfrequenzaexpiring' display-format="H" cancel-Text="" done-Text="" value=""
                                interface="action-sheet"
                                style="margin-top:12px!important;border-bottom: 1px solid rgb(206, 212, 218);height:21px!important;max-width:90px!important"
                                min="2022-03-29T01:21:23.654+02:00">

                            </ion-datetime>

                        </v-col>

                    </v-row>

                    <v-row style="margin-left:-8px!important" v-if="showRow8">

                        <v-col cols="2" md="2">

                            <label style="margin-left:11px">Lun</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_monday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Mar</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_tuesday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Mer</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_wednesday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Gio</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_thursday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Ven</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_friday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Sab</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_saturday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:8px">Dom</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_sunday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                    </v-row>


                    <v-row style="margin-left:1px!important" v-if="showRow10">

                        <v-col cols="3" md="3">

                            <label class="v-label v-label--active theme--light"
                                style="margin-top:12px!important">Dalle</label>
                            <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                                style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                                id="dalle" name="dalle" class='hourbooking' cancel-Text="" done-Text=""
                                display-format="HH:mm">
                            </ion-datetime>

                        </v-col>

                        <v-col cols="3" md="3">

                            <label class="v-label v-label--active theme--light"
                                style="margin-top:12px!important">Alle</label>
                            <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                                style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                                id="alle" name="alle" class='hourbooking' cancel-Text="" done-Text=""
                                display-format="HH:mm">
                            </ion-datetime>

                        </v-col>

                        <v-col cols="3" md="3">

                            <div id="cont_minutifreq" style="cursor:pointer">
                                <ion-item id="itemFreq"
                                    style="min-width:190px!important;transform: translateX(-6px);margin-top:-8px;margin-bottom:18px">

                                    <ion-label style="font-size:11px;cursor:pointer;opacity:1" position="floating">Freq.
                                        (MM)
                                    </ion-label>

                                    <ion-select id="minfreq" name="minfreq" class='minfreq' cancel-Text="Chiudi"
                                        done-Text="" :value="impostazioni_invio_mail.mail_minutes_tot"
                                        interface="action-sheet">

                                        <ion-select-option v-for="item in minuti_frequenza" :key="item.valore"
                                            :value='item.valore'> {{ item.descrizione }}</ion-select-option>

                                    </ion-select>
                                </ion-item>
                            </div>

                        </v-col>

                    </v-row>



                    <v-row style="margin-left:-8px!important" v-if="showRow11">

                        <v-col cols="2" md="2" style="min-width: 70px!important;">

                            <label style="margin-left:11px">Lun</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_monday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Mar</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_tuesday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Mer</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_wednesday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Gio</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_thursday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Ven</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_friday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Sab</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_saturday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:8px">Dom</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_sunday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                    </v-row>


                    <v-row v-if="showRowTitle">

                        <v-col cols="9" md="9">

                            <v-text-field v-model="impostazioni_invio_mail.titolo" label="Titolo"
                                id="fldTitolo"></v-text-field>

                        </v-col>

                    </v-row>

                    <v-row v-if="showRowDesc">

                        <v-col cols="9" md="9">

                            <v-textarea label="Descrizione" v-model="impostazioni_invio_mail.descrizione"
                                id="fldDescrizione"></v-textarea>

                        </v-col>

                    </v-row>





                    <v-row v-if="showRowMail">

                        <v-col cols="12" md="12">
                            <v-checkbox label="Invio Mail" v-model="impostazioni_invio_mail.send_mail" true-value="S"
                                false-value="N" id="chkMinutes" class="cl-checkbox" @click="chkChange"></v-checkbox>
                        </v-col>

                    </v-row>




                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-conf" contain
                                    src="@/assets/btn_cancel3_alertduedate.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Annulla" @click="btnCancelInfoConf3" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupinfoconf4" style="display:none">

            <template>
                <modal name="popupInfoConf4" :clickToClose="false" :width="343" :height="786">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Configurazione

                        </v-col>

                    </v-row>

                    <v-row
                        style="margin-top:4px!important; padding-top:0px!important;margin-bottom:0px!important;padding-bottom:0px!important">

                        <v-col cols="12" md="12"
                            style="margin-top:10px!important;font-weight:bold;margin-left:10px; margin-top:0px!important; padding-top:0px!important;margin-bottom:0px!important; padding-bottom:0px!important">

                            {{ titlePopup }}

                        </v-col>

                    </v-row>

                    <v-row style="margin-left:1px!important" v-if="showRow4">

                        <v-col cols="6" md="6">
                            Fatture Scadute
                        </v-col>

                    </v-row>

                    <v-row style="margin-left:1px!important" v-if="showRow3">

                        <v-col cols="6" md="6">

                            <label style="font-size:11px">Giorni Alla Scadenza</label>

                            <div id="d_fido_ant_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="impostazioni_invio_mail.days_before_alert"
                                                :options="'integer'" ref="fldDaysLeft"
                                                id="fldDaysLeft"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>


                    <v-row style="margin-left:1px!important" v-if="showRow12">

                        <v-col cols="6" md="6">

                            <label style="font-size:11px">Giorni A Fine Mese</label>

                            <div id="d_fido_ant_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="impostazioni_invio_mail.days_before_alert"
                                                :options="'integer'" ref="fldDaysLeft"
                                                id="fldDaysLeft"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>


                    <v-row style="margin-left:1px!important" v-if="showRow">

                        <v-col cols="3" md="3">

                            <label class="v-label v-label--active theme--light"
                                style="margin-top:12px!important">Dalle</label>
                            <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                                style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                                id="dalle" name="dalle" class='hourbooking' cancel-Text="" done-Text=""
                                display-format="HH:mm">
                            </ion-datetime>

                        </v-col>

                        <v-col cols="3" md="3">

                            <label class="v-label v-label--active theme--light"
                                style="margin-top:12px!important">Alle</label>
                            <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                                style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                                id="alle" name="alle" class='hourbooking' cancel-Text="" done-Text=""
                                display-format="HH:mm">
                            </ion-datetime>

                        </v-col>

                        <v-col cols="6" md="6">

                            <div id="cont_minutifreq" style="cursor:pointer">
                                <ion-item id="itemFreq"
                                    style="min-width:190px!important;transform: translateX(-6px);margin-top:-8px;margin-bottom:18px">

                                    <ion-label style="font-size:11px;cursor:pointer;opacity:1" position="floating">Freq.
                                        (MM)
                                    </ion-label>

                                    <ion-select id="minfreq" name="minfreq" class='minfreq' cancel-Text="Chiudi"
                                        done-Text="" :value="impostazioni_invio_mail.mail_minutes_tot"
                                        interface="action-sheet">

                                        <ion-select-option v-for="item in minuti_frequenza" :key="item.valore"
                                            :value='item.valore'> {{ item.descrizione }}</ion-select-option>

                                    </ion-select>
                                </ion-item>
                            </div>

                        </v-col>


                    </v-row>


                    <v-row style="margin-left:-8px!important" v-if="showRow">

                        <v-col cols="2" md="2" style="min-width: 70px;">

                            <label style="margin-left:11px">Lun</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_monday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Mar</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_tuesday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Mer</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_wednesday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Gio</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_thursday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Ven</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_friday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Sab</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_saturday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:8px">Dom</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_sunday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                    </v-row>

                    <v-row style="margin-left:1px!important" v-if="showRow2">

                        <v-col cols="6" md="6">

                            <label>Numero files rimanenti nella folder</label>

                            <div id="d_fido_ant_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="impostazioni_invio_mail.num_files_left"
                                                :options="'integer'" ref="fldNumFilesLeft"
                                                id="fldNumFilesLeft"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>



                    <v-row style="margin-left:1px!important" v-if="showRow5">

                        <v-col cols="6" md="6">
                            Fatture In Scadenza
                        </v-col>

                    </v-row>

                    <v-row style="margin-left:1px!important" v-if="showRow6">

                        <v-col cols="6" md="6">

                            <label style="font-size:11px">Giorni Alla Scadenza</label>

                            <div id="d_fido_ant_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="impostazioni_invio_mail.days_before_alert"
                                                :options="'integer'" ref="fldDaysLeft"
                                                id="fldDaysLeft"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>


                    <v-row style="margin-left:1px!important" v-if="showRow7">

                        <v-col cols="3" md="3">

                            <label class="v-label v-label--active theme--light"
                                style="margin-top:12px!important">Dalle</label>
                            <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                                style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                                id="dalleExpiring" name="dalleExpiring" class='hourbooking' cancel-Text="" done-Text=""
                                display-format="HH:mm">
                            </ion-datetime>

                        </v-col>

                        <v-col cols="3" md="3">

                            <label class="v-label v-label--active theme--light"
                                style="margin-top:12px!important">Alle</label>
                            <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                                style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                                id="alleExpiring" name="alleExpiring" class='hourbooking' cancel-Text="" done-Text=""
                                display-format="HH:mm">
                            </ion-datetime>

                        </v-col>

                        <v-col cols="6" md="6">

                            <!-- <label class="v-label v-label--active theme--light" style="margin-top:12px!important">Freq.
                                (H)</label>

                            <ion-datetime hour-cycle="h12" name="tipo" id="selfrequenzaexpiring"
                                class='selfrequenzaexpiring' display-format="H" cancel-Text="" done-Text="" value=""
                                interface="action-sheet"
                                style="margin-top:12px!important;border-bottom: 1px solid rgb(206, 212, 218);height:21px!important;max-width:90px!important"
                                min="2022-03-29T01:21:23.654+02:00">

                            </ion-datetime> -->

                            <div id="cont_minutifreq" style="cursor:pointer">
                                <ion-item id="itemFreq"
                                    style="min-width:190px!important;transform: translateX(-6px);margin-top:-8px;margin-bottom:18px">

                                    <ion-label style="font-size:11px;cursor:pointer;opacity:1" position="floating">Freq.
                                        (MM)
                                    </ion-label>

                                    <ion-select id="selfrequenzaexpiring" name="selfrequenzaexpiring"
                                        class='selfrequenzaexpiring' cancel-Text="Chiudi" done-Text=""
                                        :value="impostazioni_invio_mail.mail_att_expiring_minute"
                                        interface="action-sheet">

                                        <ion-select-option v-for="item in minuti_frequenza" :key="item.valore"
                                            :value='item.valore'> {{ item.descrizione }}</ion-select-option>

                                    </ion-select>
                                </ion-item>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row style="margin-left:-8px!important" v-if="showRow8">

                        <v-col cols="2" md="2">

                            <label style="margin-left:11px">Lun</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_monday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Mar</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_tuesday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Mer</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_wednesday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Gio</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_thursday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Ven</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_friday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Sab</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_saturday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:8px">Dom</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_sunday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                    </v-row>


                    <v-row style="margin-left:1px!important" v-if="showRow10">

                        <v-col cols="3" md="3">

                            <label class="v-label v-label--active theme--light"
                                style="margin-top:12px!important">Dalle</label>
                            <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                                style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                                id="dalle" name="dalle" class='hourbooking' cancel-Text="" done-Text=""
                                display-format="HH:mm">
                            </ion-datetime>

                        </v-col>

                        <v-col cols="3" md="3">

                            <label class="v-label v-label--active theme--light"
                                style="margin-top:12px!important">Alle</label>
                            <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                                style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                                id="alle" name="alle" class='hourbooking' cancel-Text="" done-Text=""
                                display-format="HH:mm">
                            </ion-datetime>

                        </v-col>

                        <v-col cols="3" md="3">

                            <div id="cont_minutifreq" style="cursor:pointer">
                                <ion-item id="itemFreq"
                                    style="min-width:190px!important;transform: translateX(-6px);margin-top:-8px;margin-bottom:18px">

                                    <ion-label style="font-size:11px;cursor:pointer;opacity:1" position="floating">Freq.
                                        (MM)
                                    </ion-label>

                                    <ion-select id="minfreq" name="minfreq" class='minfreq' cancel-Text="Chiudi"
                                        done-Text="" :value="impostazioni_invio_mail.mail_minutes_tot"
                                        interface="action-sheet">

                                        <ion-select-option v-for="item in minuti_frequenza" :key="item.valore"
                                            :value='item.valore'> {{ item.descrizione }}</ion-select-option>

                                    </ion-select>
                                </ion-item>
                            </div>

                        </v-col>

                    </v-row>



                    <v-row style="margin-left:-8px!important" v-if="showRow11">

                        <v-col cols="2" md="2" style="min-width: 70px!important;">

                            <label style="margin-left:11px">Lun</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_monday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Mar</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_tuesday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Mer</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_wednesday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Gio</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_thursday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Ven</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_friday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Sab</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_saturday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:8px">Dom</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_sunday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                    </v-row>


                    <v-row v-if="showRowTitle">

                        <v-col cols="9" md="9">

                            <v-text-field v-model="impostazioni_invio_mail.titolo" label="Titolo"
                                id="fldTitolo"></v-text-field>

                        </v-col>

                    </v-row>

                    <v-row v-if="showRowDesc">

                        <v-col cols="9" md="9">

                            <v-textarea label="Descrizione" v-model="impostazioni_invio_mail.descrizione"
                                id="fldDescrizione"></v-textarea>

                        </v-col>

                    </v-row>





                    <v-row v-if="showRowMail"
                        style="margin-top:0px!important;padding-top:0px!important;margin-bottom:0px!important;padding-bottom:0px!important">

                        <v-col cols="12" md="12"
                            style="margin-top:0px!important;padding-top:0px!important;margin-bottom:0px!important;padding-bottom:0px!important">
                            <v-checkbox label="Invio Mail" v-model="impostazioni_invio_mail.send_mail" true-value="S"
                                false-value="N" id="chkMinutes" class="cl-checkbox" @click="chkChange"></v-checkbox>
                        </v-col>

                    </v-row>




                    <v-row style="margin-top:0px!important;padding-top:0px!important">

                        <v-col cols="12" md="12" style="margin-top:0px!important;padding-top:0px!important">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-conf" contain
                                    src="@/assets/btn_cancel3_alertduedate.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Annulla" @click="btnCancelInfoConf4" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>




        <div class="contpopupinfoconf5" style="display:none">

            <template>
                <modal name="popupInfoConf5" :clickToClose="false" :width="343" :height="543">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Configurazione

                        </v-col>

                    </v-row>

                    <v-row
                        style="margin-top:4px!important; padding-top:0px!important;margin-bottom:0px!important;padding-bottom:0px!important">

                        <v-col cols="12" md="12"
                            style="margin-top:10px!important;font-weight:bold;margin-left:10px; margin-top:0px!important; padding-top:0px!important;margin-bottom:0px!important; padding-bottom:0px!important">

                            {{ titlePopup }}

                        </v-col>

                    </v-row>


                    <v-row style="margin-left:1px!important" v-if="showRow4">

                        <v-col cols="6" md="6">
                            Fatture Scadute
                        </v-col>

                    </v-row>

                    <v-row style="margin-left:1px!important" v-if="showRow3">

                        <v-col cols="6" md="6">

                            <label style="font-size:11px">Giorni Alla Scadenza</label>

                            <div id="d_fido_ant_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="impostazioni_invio_mail.days_before_alert"
                                                :options="'integer'" ref="fldDaysLeft"
                                                id="fldDaysLeft"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>


                    <v-row style="margin-left:1px!important" v-if="showRow12">

                        <v-col cols="6" md="6">

                            <label style="font-size:11px">Giorni A Fine Mese</label>

                            <div id="d_fido_ant_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="impostazioni_invio_mail.days_before_alert"
                                                :options="'integer'" ref="fldDaysLeft"
                                                id="fldDaysLeft"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>


                    <v-row style="margin-left:1px!important" v-if="showRow">

                        <v-col cols="3" md="3">

                            <label class="v-label v-label--active theme--light"
                                style="margin-top:12px!important">Dalle</label>
                            <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                                style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                                id="dalle" name="dalle" class='hourbooking' cancel-Text="" done-Text=""
                                display-format="HH:mm">
                            </ion-datetime>

                        </v-col>

                        <v-col cols="3" md="3">

                            <label class="v-label v-label--active theme--light"
                                style="margin-top:12px!important">Alle</label>
                            <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                                style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                                id="alle" name="alle" class='hourbooking' cancel-Text="" done-Text=""
                                display-format="HH:mm">
                            </ion-datetime>

                        </v-col>

                        <v-col cols="6" md="6">

                            <div id="cont_minutifreq" style="cursor:pointer">
                                <ion-item id="itemFreq"
                                    style="min-width:190px!important;transform: translateX(-6px);margin-top:-8px;margin-bottom:18px">

                                    <ion-label style="font-size:11px;cursor:pointer;opacity:1" position="floating">Freq.
                                        (MM)
                                    </ion-label>

                                    <ion-select id="minfreq" name="minfreq" class='minfreq' cancel-Text="Chiudi"
                                        done-Text="" :value="impostazioni_invio_mail.mail_minutes_tot"
                                        interface="action-sheet">

                                        <ion-select-option v-for="item in minuti_frequenza" :key="item.valore"
                                            :value='item.valore'> {{ item.descrizione }}</ion-select-option>

                                    </ion-select>
                                </ion-item>
                            </div>

                        </v-col>


                    </v-row>


                    <v-row style="margin-left:-8px!important" v-if="showRow">

                        <v-col cols="2" md="2" style="min-width: 70px;">

                            <label style="margin-left:11px">Lun</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_monday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Mar</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_tuesday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Mer</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_wednesday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Gio</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_thursday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Ven</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_friday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Sab</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_saturday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:8px">Dom</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_sunday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                    </v-row>

                    <v-row style="margin-left:1px!important" v-if="showRow2">

                        <v-col cols="6" md="6">

                            <label>Numero files rimanenti nella folder</label>

                            <div id="d_fido_ant_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="impostazioni_invio_mail.num_files_left"
                                                :options="'integer'" ref="fldNumFilesLeft"
                                                id="fldNumFilesLeft"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>



                    <v-row style="margin-left:1px!important" v-if="showRow5">

                        <v-col cols="6" md="6">
                            Fatture In Scadenza
                        </v-col>

                    </v-row>

                    <v-row style="margin-left:1px!important" v-if="showRow6">

                        <v-col cols="6" md="6">

                            <label style="font-size:11px">Giorni Alla Scadenza</label>

                            <div id="d_fido_ant_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="impostazioni_invio_mail.days_before_alert"
                                                :options="'integer'" ref="fldDaysLeft"
                                                id="fldDaysLeft"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>


                    <v-row style="margin-left:1px!important" v-if="showRow7">

                        <v-col cols="3" md="3">

                            <label class="v-label v-label--active theme--light"
                                style="margin-top:12px!important">Dalle</label>
                            <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                                style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                                id="dalleExpiring" name="dalleExpiring" class='hourbooking' cancel-Text="" done-Text=""
                                display-format="HH:mm">
                            </ion-datetime>

                        </v-col>

                        <v-col cols="3" md="3">

                            <label class="v-label v-label--active theme--light"
                                style="margin-top:12px!important">Alle</label>
                            <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                                style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                                id="alleExpiring" name="alleExpiring" class='hourbooking' cancel-Text="" done-Text=""
                                display-format="HH:mm">
                            </ion-datetime>

                        </v-col>

                        <v-col cols="6" md="6">

                            <!-- <label class="v-label v-label--active theme--light" style="margin-top:12px!important">Freq.
                    (H)</label>

                <ion-datetime hour-cycle="h12" name="tipo" id="selfrequenzaexpiring"
                    class='selfrequenzaexpiring' display-format="H" cancel-Text="" done-Text="" value=""
                    interface="action-sheet"
                    style="margin-top:12px!important;border-bottom: 1px solid rgb(206, 212, 218);height:21px!important;max-width:90px!important"
                    min="2022-03-29T01:21:23.654+02:00">

                </ion-datetime> -->

                            <div id="cont_minutifreq" style="cursor:pointer">
                                <ion-item id="itemFreq"
                                    style="min-width:190px!important;transform: translateX(-6px);margin-top:-8px;margin-bottom:18px">

                                    <ion-label style="font-size:11px;cursor:pointer;opacity:1" position="floating">Freq.
                                        (MM)
                                    </ion-label>

                                    <ion-select id="selfrequenzaexpiring" name="selfrequenzaexpiring"
                                        class='selfrequenzaexpiring' cancel-Text="Chiudi" done-Text=""
                                        :value="impostazioni_invio_mail.mail_att_expiring_minute"
                                        interface="action-sheet">

                                        <ion-select-option v-for="item in minuti_frequenza" :key="item.valore"
                                            :value='item.valore'> {{ item.descrizione }}</ion-select-option>

                                    </ion-select>
                                </ion-item>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row style="margin-left:-8px!important" v-if="showRow8">

                        <v-col cols="2" md="2">

                            <label style="margin-left:11px">Lun</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_monday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Mar</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_tuesday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Mer</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_wednesday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Gio</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_thursday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Ven</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_friday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Sab</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_saturday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:8px">Dom</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_sunday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                    </v-row>


                    <v-row style="margin-left:1px!important" v-if="showRow10">

                        <v-col cols="3" md="3">

                            <label class="v-label v-label--active theme--light"
                                style="margin-top:12px!important">Dalle</label>
                            <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                                style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                                id="dalle" name="dalle" class='hourbooking' cancel-Text="" done-Text=""
                                display-format="HH:mm">
                            </ion-datetime>

                        </v-col>

                        <v-col cols="3" md="3">

                            <label class="v-label v-label--active theme--light"
                                style="margin-top:12px!important">Alle</label>
                            <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                                style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                                id="alle" name="alle" class='hourbooking' cancel-Text="" done-Text=""
                                display-format="HH:mm">
                            </ion-datetime>

                        </v-col>

                        <v-col cols="3" md="3">

                            <div id="cont_minutifreq" style="cursor:pointer">
                                <ion-item id="itemFreq"
                                    style="min-width:190px!important;transform: translateX(-6px);margin-top:-8px;margin-bottom:18px">

                                    <ion-label style="font-size:11px;cursor:pointer;opacity:1" position="floating">Freq.
                                        (MM)
                                    </ion-label>

                                    <ion-select id="minfreq" name="minfreq" class='minfreq' cancel-Text="Chiudi"
                                        done-Text="" :value="impostazioni_invio_mail.mail_minutes_tot"
                                        interface="action-sheet">

                                        <ion-select-option v-for="item in minuti_frequenza" :key="item.valore"
                                            :value='item.valore'> {{ item.descrizione }}</ion-select-option>

                                    </ion-select>
                                </ion-item>
                            </div>

                        </v-col>

                    </v-row>



                    <v-row style="margin-left:-8px!important" v-if="showRow11">

                        <v-col cols="2" md="2" style="min-width: 70px!important;">

                            <label style="margin-left:11px">Lun</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_monday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Mar</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_tuesday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Mer</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_wednesday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Gio</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_thursday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Ven</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_friday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Sab</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_saturday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:8px">Dom</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_sunday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                    </v-row>


                    <v-row v-if="showRowTitle">

                        <v-col cols="9" md="9">

                            <v-text-field v-model="impostazioni_invio_mail.titolo" label="Titolo"
                                id="fldTitolo"></v-text-field>

                        </v-col>

                    </v-row>

                    <v-row v-if="showRowDesc">

                        <v-col cols="9" md="9">

                            <v-textarea label="Descrizione" v-model="impostazioni_invio_mail.descrizione"
                                id="fldDescrizione"></v-textarea>

                        </v-col>

                    </v-row>





                    <v-row v-if="showRowMail">

                        <v-col cols="12" md="12">
                            <v-checkbox label="Invio Mail" v-model="impostazioni_invio_mail.send_mail" true-value="S"
                                false-value="N" id="chkMinutes" class="cl-checkbox" @click="chkChange"></v-checkbox>
                        </v-col>

                    </v-row>




                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-conf" contain
                                    src="@/assets/btn_cancel3_alertduedate.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Annulla" @click="btnCancelInfoConf5" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>





        <div class="contpopupinfoconf7" style="display:none">

            <template>
                <modal name="popupInfoConf7" :clickToClose="false" :width="343" :height="560">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Configurazione

                        </v-col>

                    </v-row>

                    <v-row
                        style="margin-top:4px!important; padding-top:0px!important;margin-bottom:0px!important;padding-bottom:0px!important">

                        <v-col cols="12" md="12"
                            style="margin-top:10px!important;font-weight:bold;margin-left:10px; margin-top:0px!important; padding-top:0px!important;margin-bottom:0px!important; padding-bottom:0px!important">

                            {{ titlePopup }}

                        </v-col>

                    </v-row>


                    <v-row style="margin-left:1px!important">

                        <v-col cols="6" md="6">

                            <label style="font-size:11px">Giorni Alla Scadenza</label>

                            <div id="d_fido_ant_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="impostazioni_invio_mail.days_before_alert"
                                                :options="'integer'" ref="fldDaysLeft"
                                                id="fldDaysLeft"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>




                    <v-row style="margin-left:1px!important">

                        <v-col cols="3" md="3">

                            <label class="v-label v-label--active theme--light"
                                style="margin-top:12px!important">Dalle</label>
                            <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                                style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                                id="dalle" name="dalle" class='hourbooking' cancel-Text="" done-Text=""
                                display-format="HH:mm">
                            </ion-datetime>

                        </v-col>

                        <v-col cols="3" md="3">

                            <label class="v-label v-label--active theme--light"
                                style="margin-top:12px!important">Alle</label>
                            <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                                style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                                id="alle" name="alle" class='hourbooking' cancel-Text="" done-Text=""
                                display-format="HH:mm">
                            </ion-datetime>

                        </v-col>

                        <v-col cols="6" md="6">

                            <div id="cont_minutifreq" style="cursor:pointer">
                                <ion-item id="itemFreq"
                                    style="min-width:190px!important;transform: translateX(-6px);margin-top:-8px;margin-bottom:18px">

                                    <ion-label style="font-size:11px;cursor:pointer;opacity:1" position="floating">Freq.
                                        (MM)
                                    </ion-label>

                                    <ion-select id="minfreq" name="minfreq" class='minfreq' cancel-Text="Chiudi"
                                        done-Text="" :value="impostazioni_invio_mail.mail_minutes_tot"
                                        interface="action-sheet">

                                        <ion-select-option v-for="item in minuti_frequenza" :key="item.valore"
                                            :value='item.valore'> {{ item.descrizione }}</ion-select-option>

                                    </ion-select>
                                </ion-item>
                            </div>

                        </v-col>


                    </v-row>


                    <v-row style="margin-left:-8px!important">

                        <v-col cols="2" md="2" style="min-width: 70px;">

                            <label style="margin-left:11px">Lun</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_monday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Mar</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_tuesday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Mer</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_wednesday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Gio</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_thursday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Ven</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_friday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:11px">Sab</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_saturday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                        <v-col cols="2" md="2" class="cl_combo1">

                            <label style="margin-left:8px">Dom</label>
                            <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_sunday" true-value="S"
                                false-value="N" :disabled="fieldDis" class="cl-checkbox"
                                @click="chkChange"></v-checkbox>

                        </v-col>

                    </v-row>












                    <v-row>

                        <v-col cols="12" md="12">
                            <v-checkbox label="Invio Mail" v-model="impostazioni_invio_mail.send_mail" true-value="S"
                                false-value="N" id="chkMinutes" class="cl-checkbox" @click="chkChange"></v-checkbox>
                        </v-col>

                    </v-row>




                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-conf" contain
                                    src="@/assets/btn_cancel3_alertduedate.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Annulla" @click="btnCancelInfoConf7" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>







    </div>
</template>

<script>

import {
    bus
} from "../main";
import router from ".././router";
/* import $ from 'jquery'; */

import apiprocessi from "../utils/processi/apiprocessi";
import apiconfprocessi from "../utils/confprocessi/apiconfprocessi";
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';


export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");

        clearInterval(window.reloadGridProcess);
    },

    components: {
        VueAutonumeric,
    },


    mounted: function () {

        /*eslint-disable no-undef*/


        window.myVal = "";

        window.perpage = 60;
        bus.$off("bottomIconClickEvent");

        window.curSrcText = "";
        window.curTipologia = "";


        $(document).ready(
            function () {

                $("body").off();

                window.uname = window.$cookies.get("username");
                var uname = window.uname;

                const args = [uname, true, ['bar', 5], {
                    foo: 'baz'
                }];
                try {
                    console.log("entering user");
                    window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                    console.log("passed");

                } catch (error) {
                    // alert(error);
                }

            }
        );

        $(document).ready(function () {

            $('body').on('ionChange', '.tipologia', function () {


                pointerVue.filterTipologia = this.value;

            });

            $('body').on('click', '.selfiltri', function () {

                console.log(("EVENTO CLICK SELFILTRI"));

                $(".contpopupsearch").show();

                pointerVue.$modal.show('popupSearch');

            });

            $('body').on('click', '.selricerca', function (event) {
                console.log(event);

                pointerVue.infoConfigurazione2();



            });



            $('body').on('blur', '#fldDaysLeft', function (event) {
                console.log(event);

                console.log("DAYS BEFORE: ", pointerVue.impostazioni_invio_mail.days_before_alert);

                setTimeout(() => {

                    pointerVue.saveData();

                }, 200);



            });

            $('body').on('ionChange', '.minfreq', function (event) {
                console.log(event);

                pointerVue.impostazioni_invio_mail.mail_minutes_tot = this.value;

                setTimeout(() => {

                    pointerVue.saveData();

                }, 200);

            });



            $('body').on('ionChange', '#dalle', function (event) {
                console.log(event);


                console.log("DALLE: ", this.value);

                var fromTime = this.value.toString().substring(11, 16);
                fromTime = fromTime + ":00";

                pointerVue.impostazioni_invio_mail.mail_minutes_form_time = fromTime;


                setTimeout(() => {

                    pointerVue.saveData();

                }, 200);


            });

            $('body').on('ionChange', '#alle', function (event) {
                console.log(event);

                console.log("ALLE: ", this.value);

                var toTime = this.value.toString().substring(11, 16);
                toTime = toTime + ":00";


                pointerVue.impostazioni_invio_mail.mail_minutes_to_time = toTime;

                setTimeout(() => {

                    pointerVue.saveData();

                }, 200);


            });


            $('body').on('click', '.aggiorna', function () {



                pointerVue.showSpinner = true;
                pointerVue.$root.$children[0].showProgress = true;

                window.table.ajax.reload();


            });




            $('body').on('click', '.seltipologia', function () {

                pointerVue.sheetTipologie = true;

            });



            $('body').on('click', '.selazienda', function () {

                console.log(("EVENTO CLICK SELAZIENDA"));

                pointerVue.sheetAziende = true;

                setTimeout(() => {

                    $("#fldTextSearchAzi").focus();

                }, 500);


            });


            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });


            $('body').on('click', '.checkall', function () {


                $(".val_status").prop("checked", $(this).prop("checked"));

                console.log("CHECKALL: ", $(this).prop("checked"));


                if ($(this).prop("checked")) {

                    $("tr").addClass("active-row");

                    $("tr").eq(0).removeClass("active-row");

                }

                if (!$(this).prop("checked")) {

                    $("tr").removeClass("active-row");


                }

                pointerVue.hideShowButtonsSelRow();


            });



        });

        setTimeout(() => {

            this.initFilter();

        }, 100);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);

            // var ids = "";

            switch (data) {

                case "btn_delete":


                    this.checkRunningForDelete();


                    break;

                case "btn_exit":
                    router.push({
                        path: "/dash"
                    });
                    break;

                case "btn_add":
                    router.push({
                        path: "/editSchedToolProcesso/0"
                    });
                    break;

                case "btn_edit":


                    this.checkRunningForEdit();


                    break;


                case "btn_create_resource":


                    this.validateCreateResources();


                    break;

                case "btn_create_qr":

                    this.createQRUser();


                    break;

                case "btn_back":
                    router.push({
                        path: "/filterusers"
                    });
                    break;

                case "btn_send_psw":

                    this.sendPswToSU();

                    break;

                case "btn_avvia":

                    this.runProcesses();

                    break;

                case "btn_enable":

                    this.checkRunningForEnable();

                    break;

                case "btn_disable":

                    this.checkRunningForDisable();

                    break;

                case "btn_history_backup":

                    this.historyBackup();

                    break;


                case "btn_info_conf":

                    this.infoConfigurazione();

                    break;


                case "btn_ferma":

                    this.stopProcesses();

                    break;



                default:
                    break;

            }
        });

        this.setupButtonsGrid();

        window.start = 0;

        var pointerVue = this;

        pointerVue.setupGrid();

        this.hideShowButtons(false);



        window.reloadGridProcess = setInterval(() => {



            $(".imgStatusGDrive").attr("src", pointerVue.getUrlImage("_lib/img/waiting_gdrive.gif"));


            setTimeout(() => {

                pointerVue.checkConnGDrive();

            }, 1000);


            pointerVue.hideShowButtons(false);


            window.table.ajax.reload();


        }, 30000);





    },
    data: () => ({

        filterTipologia: "",

        titlePopup: "",

        fieldDis: false,


        minuti_frequenza: [
            { valore: 1, descrizione: "1" },
            { valore: 5, descrizione: "5" },
            { valore: 10, descrizione: "10" },
            { valore: 15, descrizione: "15" },
            { valore: 20, descrizione: "20" },
            { valore: 25, descrizione: "25" },
            { valore: 30, descrizione: "30" },
            { valore: 35, descrizione: "35" },
            { valore: 40, descrizione: "40" },
            { valore: 45, descrizione: "45" },
            { valore: 50, descrizione: "50" },
            { valore: 55, descrizione: "55" },
            { valore: 60, descrizione: "60" },
            { valore: 120, descrizione: "120" },
            { valore: 180, descrizione: "180" },
            { valore: 240, descrizione: "240" },
            { valore: 300, descrizione: "300" },
            { valore: 360, descrizione: "360" },
            { valore: 480, descrizione: "480" },
            { valore: 540, descrizione: "540" },
            { valore: 900, descrizione: "900" },
            { valore: 1380, descrizione: "1380" },
        ],


        impostazioni_invio_mail: {
            mail_enable_daily: "N",
            mail_enable_weekly: "N",
            id_tipologia: "",
            mail_minutes_monday: "N",
            mail_minutes_tuesday: "N",
            mail_minutes_wednesday: "N",
            mail_minutes_thursday: "N",
            mail_minutes_friday: "N",
            mail_minutes_saturday: "N",
            mail_minutes_sunday: "N",
            send_mail: "N",
            num_files_left: 0,
            days_before_alert: null,
            mail_minutes_tot: "",
            titolo: "",
            descrizione: "",
        },




        showRow: false,
        showRow2: false,
        showRow3: false,
        showRow4: false,
        showRow5: false,
        showRow6: false,
        showRow7: false,
        showRow8: false,
        showRow10: false,
        showRow11: false,
        showRowMail: false,
        showRow12: false,
        showRowTitle: false,
        showRowDesc: false,



        googleDriveIsActive: 1,



        enableMon: "N",
        enableTue: "N",
        enableWed: "N",
        enableThu: "N",
        enableFri: "N",
        enableSat: "N",
        enableSun: "N",
        sendMail: "N",


        oreDalle: null,
        oreAlle: null,
        freqHH: null,


        files_backup: [],

        tipologie: [],

        sheetTipologie: false,

        valueOtpDelete: "",

        sheetAziende: false,
        search: "",

        textSearch: "",

        aziende: [],


        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    computed: {

        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

    },


    methods: {

        btnResetSearch: function () {

          

            window.curSrcText = "";
            this.textSearch = "";

            window.curTipologia = "";
            this.filterTipologia = "";


            $('.selfiltri').attr('src', '/schedtool/img/schedtool-filtri-disattivi.png');


            this.$modal.hide('popupSearch');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();
        },

        getUrlDomainGo: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = "http://localhost:4012/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = "https://filestransfer.it:4012/";

            }

            return result + nome_metodo;

        },



        openPopupInfoConfOnNewConf: function (v_codice, v_nome) {

            var that = this;

            that.showRow = false;
            that.showRow2 = false;
            that.showRow3 = false;
            that.showRow4 = false;
            that.showRow5 = false;
            that.showRow6 = false;
            that.showRow7 = false;
            that.showRow8 = false;
            that.showRow10 = false;
            that.showRow11 = false;
            that.showRowMail = false;
            that.showRow12 = false;
            that.showRowTitle = false;
            that.showRowDesc = false;


            that.showRow = true;
            that.showRowMail = true;

            if (v_codice == "DELETEFILE") {
                that.showRow2 = true;
            }

            if (v_codice == "DELETEFILESOLDBACKUPEMTOOL") {
                that.showRow2 = true;
            }

            if (v_codice == "DELETEFILESOLDBACKUPPROIA") {
                that.showRow2 = true;
            }


            if (v_codice == "RISORSEINPROVA") {
                that.showRow3 = true;
            }

            if (v_codice == "INVOICEWEBSCADENZAFATTURE") {
                that.showRow4 = true;
                that.showRow5 = true;
                that.showRow6 = true;
                that.showRow7 = true;
                that.showRow8 = true;
            }


            if (v_codice == "AUTOAPPROVAZIONEFERIE/PERMESSI") {
                that.showRow10 = true;
                that.showRow11 = true;
                that.showRow = false;
            }

            if (v_codice == "AUTOAPPROVAZIONEFERIE/PERMESSINEXT3") {
                that.showRow10 = true;
                that.showRow11 = true;
                that.showRow = false;
            }

            if (v_codice == "ALERTCOMPILAZIONETIMESHEET") {
                that.showRow12 = true;
                that.showRowTitle = true;
                that.showRowDesc = true;
            }

            if (v_codice == "ALERTCOMPILAZIONETIMESHEETNEXT3") {
                that.showRow12 = true;
                that.showRowTitle = true;
                that.showRowDesc = true;
            }

            if (v_codice == "DOXWEBAGGIORNAMENTOSTATOFILECUD") {
                that.showRow10 = true;
                that.showRow11 = true;
                that.showRow = false;
            }

            if (v_codice == "DOXWEBAGGIORNAMENTOSTATOFILECEDOLINI") {
                that.showRow10 = true;
                that.showRow11 = true;
                that.showRow = false;
            }

            if (v_codice == "DOXWEBSPLITCEDOLINICUD") {
                that.showRow10 = true;
                that.showRow11 = true;
                that.showRow = false;
            }

            if (v_codice == "DOMAINBOOST") {
                that.showRow10 = true;
                that.showRow11 = true;
                that.showRow = false;
            }


            that.titlePopup = v_nome;


            if ((v_codice == "ALERTCOMPILAZIONETIMESHEET") || (v_codice == "ALERTCOMPILAZIONETIMESHEETNEXT3")) {

                $(".contpopupinfoconf").show();

                that.$modal.show('popupInfoConf');

            }
            else {

                if ((v_codice == "DELETEFILE") || (v_codice == "DELETEFILESOLDBACKUPEMTOOL") || (v_codice == "DELETEFILESOLDBACKUPPROIA")) {

                    $(".contpopupinfoconf3").show();

                    that.$modal.show('popupInfoConf3');

                }
                else {

                    if (v_codice == "INVOICEWEBSCADENZAFATTURE") {

                        $(".contpopupinfoconf4").show();

                        that.$modal.show('popupInfoConf4');

                    }
                    else {

                        if (v_codice == "RISORSEINPROVA") {

                            $(".contpopupinfoconf5").show();

                            that.$modal.show('popupInfoConf5');

                        }
                        else {


                            if (v_codice == "ALERTDUEDATEPAGAMENTIINSCADENZA") {

                                $(".contpopupinfoconf7").show();

                                that.$modal.show('popupInfoConf7');
                            }
                            else {

                                $(".contpopupinfoconf2").show();

                                that.$modal.show('popupInfoConf2');
                            }






                        }


                    }



                }




            }

        },


        getConfProcesso: async function (id_tipologia, v_codice, v_desc) {


            var v_token = window.$cookies.get("token");

            var that = this;

            that.loadEdit = 1;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiconfprocessi.getConfProcesso(
                v_token,
                id_tipologia
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getConfProcesso", res);


                that.showRow = false;
                that.showRow2 = false;
                that.showRow3 = false;
                that.showRow4 = false;
                that.showRow5 = false;
                that.showRow6 = false;
                that.showRow7 = false;
                that.showRow8 = false;
                that.showRow10 = false;
                that.showRow11 = false;
                that.showRowMail = false;
                that.showRow12 = false;
                that.showRowTitle = false;
                that.showRowDesc = false;


                that.showRow = true;
                that.showRowMail = true;

                if (v_codice == "DELETEFILE") {
                    that.showRow2 = true;
                }

                if (v_codice == "DELETEFILESOLDBACKUPEMTOOL") {
                    that.showRow2 = true;
                }

                if (v_codice == "DELETEFILESOLDBACKUPPROIA") {
                    that.showRow2 = true;
                }


                if (v_codice == "RISORSEINPROVA") {
                    that.showRow3 = true;
                }


                if (v_codice == "INVOICEDOCUMENTICLIENTISCADUTI") {
                    that.showRow3 = true;
                }




                if (v_codice == "INVOICEWEBSCADENZAFATTURE") {
                    that.showRow4 = true;
                    that.showRow5 = true;
                    that.showRow6 = true;
                    that.showRow7 = true;
                    that.showRow8 = true;
                }


                if (v_codice == "AUTOAPPROVAZIONEFERIE/PERMESSI") {
                    that.showRow10 = true;
                    that.showRow11 = true;
                    that.showRow = false;
                }

                if (v_codice == "AUTOAPPROVAZIONEFERIE/PERMESSINEXT3") {
                    that.showRow10 = true;
                    that.showRow11 = true;
                    that.showRow = false;
                }

                if (v_codice == "ALERTCOMPILAZIONETIMESHEET") {
                    that.showRow12 = true;
                    that.showRowTitle = true;
                    that.showRowDesc = true;
                }

                if (v_codice == "ALERTCOMPILAZIONETIMESHEETNEXT3") {
                    that.showRow12 = true;
                    that.showRowTitle = true;
                    that.showRowDesc = true;
                }

                if (v_codice == "DOXWEBAGGIORNAMENTOSTATOFILECUD") {
                    that.showRow10 = true;
                    that.showRow11 = true;
                    that.showRow = false;
                }

                if (v_codice == "DOXWEBAGGIORNAMENTOSTATOFILECEDOLINI") {
                    that.showRow10 = true;
                    that.showRow11 = true;
                    that.showRow = false;
                }

                if (v_codice == "DOXWEBSPLITCEDOLINICUD") {
                    that.showRow10 = true;
                    that.showRow11 = true;
                    that.showRow = false;
                }

                if (v_codice == "DOMAINBOOST") {
                    that.showRow10 = true;
                    that.showRow11 = true;
                    that.showRow = false;
                }


                that.titlePopup = v_desc;


                if ((v_codice == "ALERTCOMPILAZIONETIMESHEET") || (v_codice == "ALERTCOMPILAZIONETIMESHEETNEXT3")) {

                    $(".contpopupinfoconf").show();

                    that.$modal.show('popupInfoConf');

                }
                else {

                    if ((v_codice == "DELETEFILE") || (v_codice == "DELETEFILESOLDBACKUPEMTOOL") || (v_codice == "DELETEFILESOLDBACKUPPROIA")) {

                        $(".contpopupinfoconf3").show();

                        that.$modal.show('popupInfoConf3');

                    }
                    else {

                        if (v_codice == "INVOICEWEBSCADENZAFATTURE") {

                            $(".contpopupinfoconf4").show();

                            that.$modal.show('popupInfoConf4');

                        }
                        else {

                            if (v_codice == "RISORSEINPROVA") {

                                $(".contpopupinfoconf5").show();

                                that.$modal.show('popupInfoConf5');

                            }
                            else {


                                if (v_codice == "ALERTDUEDATEPAGAMENTIINSCADENZA") {

                                    $(".contpopupinfoconf7").show();

                                    that.$modal.show('popupInfoConf7');
                                }
                                else {

                                    $(".contpopupinfoconf2").show();

                                    that.$modal.show('popupInfoConf2');
                                }






                            }


                        }



                    }




                }





                setTimeout(() => {


                    try {

                        if (res.data.Result != null) {

                            that.impostazioni_invio_mail = res.data.Result;

                            if (res.data.Result.mail_minutes_form_time != null) {


                                if (res.data.Result.mail_minutes_form_time != "00:00:00") {

                                    $("#dalle").val(res.data.Result.mail_minutes_form_time);

                                }
                                else {

                                    if (id_tipologia == "AUTOAPPROVAZIONEFERIE/PERMESSI") {


                                        $("#dalle").val("00:00");

                                    }
                                    else {

                                        $("#dalle").val("00:00");

                                    }


                                }



                            }

                            if (res.data.Result.mail_minutes_to_time != null) {

                                if (res.data.Result.mail_minutes_to_time != "00:00:00") {

                                    $("#alle").val(res.data.Result.mail_minutes_to_time);

                                }
                                else {

                                    $("#alle").val("");

                                }




                            }

                            if (res.data.Result.mail_minutes_tot != null) {



                                $("#selfrequenza").val(res.data.Result.mail_minutes_tot);

                            }

                            if (res.data.Result.mail_att_expiring_time_from != null) {

                                $("#dalleExpiring").val(res.data.Result.mail_att_expiring_time_from);

                            }

                            if (res.data.Result.mail_att_expiring_time_to != null) {

                                $("#alleExpiring").val(res.data.Result.mail_att_expiring_time_to);

                            }

                            if (res.data.Result.mail_att_expiring_minute != null) {



                                $("#selfrequenzaexpiring").val(res.data.Result.mail_att_expiring_minute);

                            }






                        }
                        else {

                            setTimeout(() => {

                                $("#dalle").val("");
                                $("#alle").val("");
                                $("#selfrequenza").val("");

                                that.impostazioni_invio_mail.mail_minutes_monday = "N";
                                that.impostazioni_invio_mail.mail_minutes_tuesday = "N";
                                that.impostazioni_invio_mail.mail_minutes_wednesday = "N";
                                that.impostazioni_invio_mail.mail_minutes_thursday = "N";
                                that.impostazioni_invio_mail.mail_minutes_friday = "N";
                                that.impostazioni_invio_mail.mail_minutes_saturday = "N";
                                that.impostazioni_invio_mail.mail_minutes_sunday = "N";
                                that.impostazioni_invio_mail.send_mail = "N";

                            }, 300);





                        }

                        that.loadEdit = 0;

                    } catch (error) {
                        console.log(error);
                    }

                }, 200);




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        saveData: async function () {

            var v_token = window.$cookies.get("token");
            var that = this;

            console.log("TOKEN ", v_token);
            console.log("THAT ", that);

            console.log("PROCESSO: ", that.impostazioni_invio_mail);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiconfprocessi.setConfProcesso(
                that.impostazioni_invio_mail,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from setConfProcesso", res);


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },

        chkChange: function () {


            this.saveData();


        },



        getUrlImage: function (path_image) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/public/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/public/";

            }

            return result + path_image;

        },

        btnCancelInfoConf: function () {

            this.$modal.hide('popupInfoConf');

        },

        btnCancelInfoConf2: function () {

            this.$modal.hide('popupInfoConf2');

        },

        btnCancelInfoConf3: function () {

            this.$modal.hide('popupInfoConf3');

        },

        btnCancelInfoConf7: function () {

            this.$modal.hide('popupInfoConf7');

        },

        btnCancelInfoConf4: function () {

            this.$modal.hide('popupInfoConf4');

        },

        btnCancelInfoConf5: function () {

            this.$modal.hide('popupInfoConf5');

        },

        infoConfigurazione2: async function () {




            var that = this;



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiprocessi.infoConfigurazione(
                13
            ).then(async (res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from infoConfigurazione", res);


                that.textSearch = "";

                $(".contpopupsearch").show();

                that.$modal.show('popupSearch');

                setTimeout(() => {

                    $("#fldSearch").focus();

                }, 200);


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },




        infoConfigurazione: async function () {

            var ids = ""

            var code_tipologia = "";

            var nome_tipologia = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");

                    code_tipologia = $(this).attr("code-tipologia");

                    nome_tipologia = $(this).attr("tipologia-nome");
                }

            });

            console.log("IDS SEL:", ids);

            var that = this;



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiprocessi.infoConfigurazione(
                ids
            ).then(async (res) => {

                console.log("res from infoConfigurazioneGo", res);

                if (res.data.Result != null) {

                    that.getConfProcesso(res.data.Result.id, res.data.Result.codice, res.data.Result.descrizione);

                }
                else {

                    that.$root.$children[0].showProgress = false;

                    that.openPopupInfoConfOnNewConf(code_tipologia, nome_tipologia);

                }






            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },





        historyBackup: async function () {

            var ids = "";
            var code_processo = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");

                    code_processo = $(this).attr("code-processo");

                }

            });

            console.log("IDS SEL:", ids);
            console.log("CODE PROCESSO:", code_processo);

            var that = this;



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiprocessi.historyBackup(
                code_processo
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from historyBackup", res);


                that.files_backup = res.data.Result;

                setTimeout(() => {

                    $(".contpopuphistorybackup").show();

                    that.$modal.show('popupHistoryBackup');

                }, 200);






            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },



        btnCancelHistoryBackup: function () {

            this.$modal.hide('popupHistoryBackup');

        },


        checkRunningForDisable: async function () {

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);

            var that = this;



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiprocessi.checkRunning(
                ids
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from checkRunning", res);

                if (res.data.Result == "OK") {

                    that.changeState("D");

                }
                else {

                    that.$swal({
                        icon: "error",
                        text: "I processi avviati non si possono disabilitare",
                        showConfirmButton: false,
                        timer: 3000
                    });


                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );








        },



        changeState: async function (v_state) {

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);

            var that = this;



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiprocessi.changeState(
                ids,
                v_state
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from changeState", res);

                that.$root.$children[0].showProgress = false;
                console.log("res from deleteProcesso", res);

                var str_msg = "";

                if (v_state == "A") {

                    str_msg = "Processi abilitati con successo";

                }

                if (v_state == "D") {

                    str_msg = "Processi disabilitati con successo";

                }

                that.$swal({
                    icon: "success",
                    text: str_msg,
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    that.dialogMsg = false;
                    that.setupButtons();

                    window.table.ajax.reload();

                    that.hideShowButtons(false);


                }, 200);



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );








        },



        checkRunningForEnable: async function () {

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);

            var that = this;



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiprocessi.checkRunning(
                ids
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from checkRunning", res);

                if (res.data.Result == "OK") {

                    that.changeState("A");

                }
                else {

                    that.$swal({
                        icon: "error",
                        text: "I processi avviati non si possono abilitare",
                        showConfirmButton: false,
                        timer: 3000
                    });


                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );








        },



        checkRunningForDelete: async function () {

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);

            var that = this;



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiprocessi.checkRunning(
                ids
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from checkRunning", res);

                if (res.data.Result == "OK") {

                    that.deleteRow();

                }
                else {

                    that.$swal({
                        icon: "error",
                        text: "I processi avviati non si possono eliminare",
                        showConfirmButton: false,
                        timer: 3000
                    });


                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );








        },



        checkRunningForEdit: async function () {

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);

            var that = this;



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiprocessi.checkRunning(
                ids
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from checkRunning", res);

                if (res.data.Result == "OK") {

                    var ids2 = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids2 != "") {
                                ids2 = ids2 + ",";
                            }
                            ids2 = ids2 + $(this).attr("data-id");
                        }

                    });

                    console.log("IDS SEL:", ids2);

                    router.push({
                        path: "/editSchedToolProcesso/" + ids2
                    });

                }
                else {

                    that.$swal({
                        icon: "error",
                        text: "I processi avviati non si possono modificare",
                        showConfirmButton: false,
                        timer: 3000
                    });


                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );








        },



        runProcesses: async function () {

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);

            var that = this;



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiprocessi.runProcesses(
                ids
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from runProcesses", res);

                if (res.data.Result == "OK") {

                    that.$swal({
                        icon: "success",
                        text: "Processi avviati con successo",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        window.table.ajax.reload();

                        that.hideShowButtons(false);

                    }, 200);

                }
                else {

                    that.$swal({
                        icon: "error",
                        text: res.data.Error,
                        showConfirmButton: false,
                        timer: 3000
                    });


                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );








        },





        stopProcesses: async function () {

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);

            var that = this;



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiprocessi.stopProcesses(
                ids
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from runProcesses", res);

                if (res.data.Result == "OK") {

                    that.$swal({
                        icon: "success",
                        text: "Processi fermati con successo",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        window.table.ajax.reload();

                        that.hideShowButtons(false);

                    }, 200);

                }
                else {

                    that.$swal({
                        icon: "error",
                        text: res.data.Error,
                        showConfirmButton: false,
                        timer: 3000
                    });


                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );








        },







        // filterTipologia: async function (id_tipologia) {

        //     console.log(status);

        //     this.sheetTipologie = false;


        //     window.curTipologia = id_tipologia;

        //     this.showSpinner = true;
        //     this.$root.$children[0].showProgress = true;

        //     window.table.ajax.reload();



        // },


        initFilter: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiprocessi.initFilter(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initFilter", res);


                that.tipologie = res.data.Tipologia;

                // that.googleDriveIsActive = res.data.StatusConn;


                // if (res.data.StatusConn == 1) {


                //     $(".imgStatusGDrive").attr("src", that.getUrlImage("_lib/img/usr__NM__bg__NM__green_ball.png"));

                // }


                // if (res.data.StatusConn == 0) {


                //     $(".imgStatusGDrive").attr("src", that.getUrlImage("_lib/img/usr__NM__bg__NM__red_ball.png"));

                // }





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },



        checkConnGDrive: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiprocessi.checkConnGDrive(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from checkConnGDrive", res);


                that.googleDriveIsActive = res.data.StatusConn;


                if (res.data.StatusConn == 1) {


                    $(".imgStatusGDrive").attr("src", that.getUrlImage("_lib/img/usr__NM__bg__NM__green_ball.png"));

                }


                if (res.data.StatusConn == 0) {


                    $(".imgStatusGDrive").attr("src", that.getUrlImage("_lib/img/usr__NM__bg__NM__red_ball.png"));

                }



                if (res.data.ReloadGrid == 1) {

                    console.log("RELOAD");

                    that.showSpinner = true;
                    that.$root.$children[0].showProgress = true;

                    window.table.ajax.reload();



                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },





        getOtpDelete: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);


            window.myVal = "";


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiotp.generateOTPAndSendMail(
                v_token,
                'emt',
                'elimina_utenti',
                ids
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from generateOTPAndSendMail", res);

                window.myVal = res.data.Result;


                setTimeout(() => {

                    $(".contpopupotpdelete").show();

                    this.$modal.show('popupOtpDelete');

                    setTimeout(() => {

                        $("#fldValueOtpDelete").focus();

                    }, 300);


                }, 200);





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );






        },


        btnCancelOtpDelete: function () {

            this.$modal.hide('popupOtpDelete');


        },

        btnConfirmOtpDelete: function () {


            if (this.valueOtpDelete != "") {

                console.log("OTP INP: ", this.valueOtpDelete);
                console.log("ENC OTP INP: ", btoa(this.valueOtpDelete));

                if (window.myVal == btoa(this.valueOtpDelete)) {

                    this.$modal.hide('popupOtpDelete');

                    this.deleteRow();

                }
                else {

                    this.$swal({
                        icon: "error",
                        text: "Valore OTP non corretto",
                        showConfirmButton: false,
                        timer: 2000
                    });


                }



            }
            else {

                this.$swal({
                    icon: "error",
                    text: "Specificare un  valore dell'OTP",
                    showConfirmButton: false,
                    timer: 2000
                });

            }



        },





        sendPswToSU: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.sendPswToSU(
                ids,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from sendPswToSU", res);

                that.$swal({
                    icon: "success",
                    text: "Dati inviati correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                window.table.ajax.reload();


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );

        },

        createQRUser: async function () {

            var that = this;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.validateCreateQRCode(
                ids,
                v_token
            ).then(async (res) => {

                console.log("res from validateCreateQRCode", res);

                var response2 = await apiusers.createQRCode(
                    ids,
                    v_token
                ).then((res2) => {

                    console.log(response2);

                    that.$root.$children[0].showProgress = false;
                    console.log("res from createQRCode", res2);

                    that.$swal({
                        icon: "success",
                        text: "QRCode Generati Con Successo",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        router.push({
                            path: "/usersQRCodeGenerated"
                        });

                    }, 2000);







                }).catch(err2 => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err2);
                    var msg = err2.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile eliminare i dati");
                    console.log("response", response);

                }

                );







            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },

        btnCancelSearch: function () {

            this.$modal.hide('popupSearch');

        },



        btnConfirmSearch: function () {

            console.log("ok");

            window.curSrcText = this.textSearch;

            window.curTipologia = this.filterTipologia;

            $('.selfiltri').attr('src', '/schedtool/img/schedtool-filtri-attivi.png');

            var filtro1 = 1;
            if (this.textSearch == "") {
                filtro1 = 0;
            }

            var filtro2 = 1;
            if (this.filterTipologia == "") {
                filtro2 = 0;
            }

            if ((filtro1 == 0) && (filtro2 == 0)) {

                $('.selfiltri').attr('src', '/schedtool/img/schedtool-filtri-disattivi.png');
            }


            this.$modal.hide('popupSearch');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },

        hideShowButtonsSelRow: function () {


            var tot = 0;
            var tot_running = 0;
            var tot_disabled = 0;
            var tot_backup_db = "";

            var tot_tipo_gdrive = 0;

            var models = window.$cookies.get("models");

            console.log(models);

            var view_btn_res_qt_code = 1;

            if (models != null) {

                var elements = models.split(",");

                for (var x = 0; x <= elements.length - 1; x++) {

                    if ((parseInt(elements[x]) == 9) || (parseInt(elements[x]) == 10)) {

                        view_btn_res_qt_code = 0;

                    }


                }


            }

            console.log("VIEW: ", view_btn_res_qt_code);

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                    var is_running = $(this).attr("is-running");

                    if (is_running == "S") {
                        tot_running = tot_running + 1;
                    }

                    var is_enabled = $(this).attr("is-enabled");

                    if (is_enabled == "N") {
                        tot_disabled = tot_disabled + 1;
                    }


                    var code_tipologia = $(this).attr("code-tipologia");

                    if (code_tipologia == "BACKUPDB") {
                        tot_backup_db = 1;
                    }


                    var tipologia_id = $(this).attr("tipologia-id");

                    if (tipologia_id == 4) {
                        tot_tipo_gdrive = tot_tipo_gdrive + 1;
                    }




                }

            });

            console.log("BACKUP DB: ", tot_backup_db);


            var view_btn_enable = 1;
            if (tot_tipo_gdrive > 0) {

                if (this.googleDriveIsActive == 0) {

                    view_btn_enable = 0;

                }

            }


            setTimeout(() => {

                var pulsantis = [];

                if (tot == 0) {

                    pulsantis.push({
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/add-alertduedate.png",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                        title: "Aggiungi",
                        width: 30
                    });


                }


                if (tot == 1) {

                    if (tot_running == 0) {

                        pulsantis.push({
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/edit-alertduedate.png",
                            link: "/info",
                            id: "btn_edit",
                            disabled: false,
                            title: "Modifica",
                            width: 30
                        });


                        if (tot_backup_db > 0) {

                            pulsantis.push({
                                text: "Edit",
                                icon: "mdi-home-circle",
                                image: "https://app.emtool.eu/public/_lib/img/files_bkp_schedtool.png",
                                link: "/info",
                                id: "btn_history_backup",
                                disabled: false,
                                title: "Storico Backup",
                                width: 22
                            });


                        }

                        pulsantis.push({
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/conf_processo_info_schedtool.png",
                            link: "/info",
                            id: "btn_info_conf",
                            disabled: false,
                            title: "Configurazione",
                            width: 22
                        });



                    }






                }

                if (tot > 0) {

                    if (tot_running == 0) {

                        pulsantis.push({
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/delete-alertduedate.png",
                            link: "/info",
                            id: "btn_delete",
                            disabled: false,
                            title: "Elimina",
                            width: 30
                        });

                        console.log(view_btn_enable);


                        // if (view_btn_enable == 1) {

                        pulsantis.push({
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/abilita_schedtool.png",
                            link: "/info",
                            id: "btn_enable",
                            disabled: false,
                            title: "Abilita",
                            width: 30
                        });

                        //  }


                        pulsantis.push({
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/disabilita_schedtool.png",
                            link: "/info",
                            id: "btn_disable",
                            disabled: false,
                            title: "Disabilita",
                            width: 30
                        });



                        if (tot_disabled == 0) {

                            pulsantis.push({
                                text: "Delete",
                                icon: "mdi-home-circle",
                                image: "https://app.emtool.eu/public/_lib/img/run_schedtool.png",
                                link: "/info",
                                id: "btn_avvia",
                                disabled: false,
                                title: "Avvia",
                                width: 25
                            });

                        }



                    }


                    if (tot_running > 0) {

                        pulsantis.push({
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/stop_schedtool.png",
                            link: "/info",
                            id: "btn_ferma",
                            disabled: false,
                            title: "Ferma",
                            width: 30
                        });

                    }



                }




                this.$root.$children[0].bnavbuttons = pulsantis;



            }, 100);



        },


        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },

        setupGrid: function () {



            // var filtri = `<img src='/schedtool/img/refresh-alertduedate.png' style='border:0!important;' class='aggiorna' title='Aggiorna'' /><img src='/schedtool/img/tipologie-alertduedate.png' style='border:0!important;' class='seltipologia' title='Tipologia'' />`;

            var filtri = `<img src='/schedtool/img/refresh-alertduedate.png' style='border:0!important;' class='aggiorna' title='Aggiorna'' /><img src='/schedtool/img/schedtool-filtri-disattivi.png' style='border:0!important;' class='selfiltri' title='Filtri' />`;


            // var that = this;
            //  var pathLocal = that.$withBase('/');

            var pointerVue = this;

            //var dateTest = new Date();
            //dateTest = dateTest.setDate(dateTest.getDate() + 365);
            //const dateTest2 = new Date(dateTest).toJSON().slice(0, 10);

            //console.log("DATE: " + dateTest2) //2015-07-23

            const date = new Date().toJSON().slice(0, 10)

            //console.log("DATE: " + date) //2015-07-23

            //  var $futureTime = dateTest2;

            $(document).ready(function () {

                var v_token = window.$cookies.get("token");
                //var v_company = window.$cookies.get("sel_filter_azienda_utenti");

                window.columns = [{
                    data: 'sel_row',
                    orderable: false,
                    searchable: false,
                    name: '',
                    width: '1%',
                },

                {
                    data: 'DT_RowIndex',
                    orderable: false,
                    searchable: false,
                    name: '',
                    width: '1%',
                },


                {
                    data: 'nome_tipologia',
                    orderable: true,
                    name: 'nome_tipologia',
                    width: '17%'
                },

                {
                    data: 'descrizione',
                    orderable: true,
                    name: 'descrizione',
                    width: '20%'
                },

                {
                    data: 'enabled',
                    orderable: true,
                    name: 'enabled',
                    width: '1%'
                },

                {
                    data: 'running',
                    orderable: true,
                    name: 'running',
                    width: '1%'
                },

                {
                    data: 'date_time_start',
                    orderable: true,
                    name: 'date_time_start',
                    width: '1%',

                    render: function (data, row) {
                        console.log(data, row);

                        var date = "";
                        try {

                            date = data.substring(0, 10);
                        } catch (error) {
                            console.log(error);
                        }

                        var hour = "";
                        try {

                            hour = data.split(" ")[1].slice(0, 5);
                        } catch (error) {
                            console.log(error);
                        }

                        var html = "<div class='font-row-grid-datetime'><div class='cl-time'>" + hour + "</div><div class='cl-date'>" + date + "</div></div>";

                        return html;
                    }

                },

                {
                    data: 'date_time_end',
                    orderable: true,
                    name: 'date_time_end',
                    width: '1%',


                    render: function (data, row) {
                        console.log(data, row);

                        var date = "";
                        try {

                            date = data.substring(0, 10);
                        } catch (error) {
                            console.log(error);
                        }

                        var hour = "";
                        try {

                            hour = data.split(" ")[1].slice(0, 5);
                        } catch (error) {
                            console.log(error);
                        }

                        var html = "<div class='font-row-grid-datetime'><div class='cl-time'>" + hour + "</div><div class='cl-date'>" + date + "</div></div>";

                        return html;
                    }


                },

                {
                    data: 'invio_mail',
                    orderable: false,
                    name: 'invio_mail',
                    width: '6%'
                },

                {
                    data: 'dalle',
                    orderable: true,
                    name: 'dalle',
                    width: '6%'
                },

                {
                    data: 'alle',
                    orderable: true,
                    name: 'alle',
                    width: '6%'
                },

                {
                    data: 'freq',
                    orderable: true,
                    name: 'freq',
                    width: '6%'
                },

                ];



                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": window.perpage,

                    processing: false,
                    paging: false,
                    serverSide: false,

                    /*  pageSize: 30, */
                    "sDom": "lfrti",

                    data: window.dataset,




                    ajax: {
                        url: pointerVue.getUrlDomain("SchedToolGetProcessi") + '?username=' + v_token,
                        data: function (valori) {

                            console.log("VALORI:", valori);

                            try {
                                window.table.clear().draw();

                            } catch (error) {
                                console.log(error);
                            }

                            window.start = window.perpage;

                            valori.start = 0;
                            valori.length = window.perpage;
                            valori.currDay = window.currDay;
                            valori.currDayTo = window.currDayTo;
                            valori.curStatus = window.curStatus;
                            valori.curPiano = window.curPiano;

                            valori.curSrcText = window.curSrcText;
                            valori.company = window.company;

                            valori.curTipologia = window.curTipologia;

                            window.valori = valori;
                            return valori;

                        }

                    },



                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: window.columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();

                        setTimeout(function () {

                            pointerVue.showSpinner = false;
                            pointerVue.$root.$children[0].showProgress = false;

                        }, 700);


                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });

                        setTimeout(function () {

                            //   $("#myTable_filter").width($("#myTable").width());

                        }, 300);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();

                        // $('.p_valore').number(true, 1, ',', '.');
                        // $('.p_totale').number(true, 2, ',', '.');
                    }

                });




                //Events
                //START SETUP
                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTable_wrapper').on('scroll', function () {
                    if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                        if (window.loading == 0) {
                            $(".spin").show();
                            var valori = window.valori;
                            valori.start = window.start;

                            $.ajax({
                                url: pointerVue.getUrlDomain("SchedToolGetProcessi") + '?username=' + v_token,
                                data: window.valori,
                                success: function (data) {
                                    $(".spin").hide();
                                    window.loading = 0;
                                    window.start += window.perpage;

                                    for (let [index, val] of data.data.entries()) {
                                        console.log(index, val);
                                        window.table.row.add(val).draw(false);
                                        //  window.dataset.push(val);
                                    }
                                    console.log(data.data);
                                },
                            });
                        }
                        window.loading = 1;
                    }
                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");

                $("#myTable_filter").find("label").remove();


                setTimeout(() => {



                    if (pointerVue.googleDriveIsActive == 1) {


                        $("#myTable_filter").prepend(`
<div class="cont_buttons">

<div class="text_title">Processi</div>
` + filtri + `

<div style='margin-left:35px;margin-top:8px'><img src='/schedtool/img/google_drive.png' style='border:0!important;width:20px'/></div>

<div style='margin-left:9px;margin-top:8px'><img class='imgStatusGDrive' src='/schedtool/img/usr__NM__bg__NM__green_ball.png' style='border:0!important;width:10px'/></div>

</div>




`);

                    }
                    else {

                        $("#myTable_filter").prepend(`
<div class="cont_buttons">

<div class="text_title">Processi</div>
` + filtri + `

<div style='margin-left:35px;margin-top:8px'><img src='/schedtool/img/google_drive.png' style='border:0!important;width:20px'/></div>

<div style='margin-left:9px;margin-top:8px'><img class='imgStatusGDrive' src='/schedtool/img/usr__NM__bg__NM__red_ball.png' style='border:0!important;width:10px'/></div>

</div>



<div><img src='/schedtool/img/ricerca.png' style='border:0!important;' class='selricerca' title='Ricerca' /></div>

`);


                    }



                }, 300);










                $(".selaggiorna").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.showSpinner = true;
                    pointerVue.$root.$children[0].showProgress = true;

                    window.table.ajax.reload();



                });






                $('body').on('click', '.paginate_button', function () {
                    $(".spin").show();

                });

                $('body').on('click', '.refreshButton', function () {

                    $(".spin").show();
                    window.table._fnDraw();

                });

                $('body').on('click', '.cont_schedtool_processi tr', function () {


                    console.log("TR-ACTIVE");

                    setTimeout(() => {

                        var row_data = window.table.row(this).data();

                        if (row_data != null) {


                            console.log("ROW DATA: ", row_data);


                            var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            var id_sel = $(this).find(".id_row").attr("data-id");


                            var set_cls_active = 0;

                            if (last_id_sel == "undefined") {
                                set_cls_active = 1;
                            } else {
                                if (last_id_sel != id_sel) {
                                    set_cls_active = 1;
                                }
                            }

                            console.log("ROM IS ACTIVE: ", set_cls_active);



                            console.log("THIS: ", this);

                            var hasClassActive = $(this).hasClass("active-row");

                            if (hasClassActive) {

                                console.log("ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", false);

                                $(this).removeClass("active-row");


                            }

                            if (!hasClassActive) {

                                console.log("NOT ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", true);

                                $(this).addClass("active-row");



                            }

                            window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            if (window.id_par_sel == undefined) {
                                window.id_par_sel = id_sel;
                            }

                            console.log("ID SELECTED: ", window.id_par_sel);


                            console.log("FIND VAL_STATUS: ", $(this).find(".val_status"));

                            var find_check = $(this).find(".val_status");
                            if (find_check.is(":checked")) {
                                console.log("SELEZIONATO");

                            }

                            if (!find_check.is(":checked")) {
                                console.log("DESELEZIONATO");

                            }


                            pointerVue.hideShowButtonsSelRow();


                        }



                    }, 100);




                });

                // }

                //setup ionic calendar top
                setTimeout(function () {

                    $("body").on("ionChange", ".dateBook", function (opt) {

                        console.log(opt);
                        var date = this.value;
                        //var dateMysql = date.toJSON().slice(0, 19).replace('T', ' ')   ;
                        dateMysql = date.substr(0, 10);

                        console.log(dateMysql);

                        $.ajax({
                            url: "{{ route('smartbook.updateDate') }}",
                            data: {
                                "id": $(this).data("id"),
                                "date": dateMysql
                            },
                        });

                    });

                    $(".selectstatusall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curStatus = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    $(".selectpianiall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curPiano = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    window.currDay = date;
                    window.currDayTo = date;

                }, 600);

                window.parentiAddedEvents = true;

            });

        },

        hideShowButtons: async function (v_enable) {

            setTimeout(() => {

                var pulsantis = [];


                if (v_enable == false) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/add-alertduedate.png",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            title: "Aggiungi",
                            width: 30
                        },

                        // {
                        //     text: "Indietro",
                        //     icon: "mdi-arrow-left",
                        //     image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                        //     link: "/confOrariChoice",
                        //     id: "btn_back",
                        //     disabled: false,
                        //     title: "Indietro",
                        //     width: 35
                        // },

                    ];

                }

                if (v_enable == true) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/add-alertduedate.png",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            title: "Aggiungi",
                            width: 30
                        },

                        {
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/edit-alertduedate.png",
                            link: "/info",
                            id: "btn_edit",
                            disabled: false,
                            title: "Modifica",
                            width: 30

                        },

                        {
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/icon_delete_emt.png",
                            link: "/info",
                            id: "btn_delete",
                            disabled: false,
                            title: "Elimina",
                            width: 30

                        },

                        // {
                        //     text: "Indietro",
                        //     icon: "mdi-arrow-left",
                        //     image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                        //     link: "/confOrariChoice",
                        //     id: "btn_back",
                        //     disabled: false,
                        //     title: "Indietro",
                        //     width: 35
                        // },


                    ];

                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/add-alertduedate.png",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                        width: 30
                    },

                    {
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/edit-alertduedate.png",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                        width: 30
                    },

                    {
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/icon_delete_emt.png",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                        width: 30
                    },

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/qrconfirm",
                        id: "btn_qrcode",
                        image: "https://app.smart-book.it/public/smartbook/ICON_3.png",
                        disabled: false,
                    },

                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                    {
                        text: "Pdf",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/sb-export-pdf-50.png",
                        class: "inverted",
                        link: "/pdf",
                        id: "btn_pdf",
                        disabled: false,
                    },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },

        validateCreateResources: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.validateCreateResources(
                ids,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from validateCreateResources", res);

                if (res.data.Result == "OK") {

                    console.log("IDS SEL 2:", ids);

                    window.$cookies.set("sel_id_users_fro_create_res", ids, "9y");

                    router.push({
                        path: "/createResourceFromUser"
                    });

                }
                else {

                    that.$swal({
                        icon: "error",
                        text: res.data.Errori,
                        showConfirmButton: false,
                        timer: 3000
                    });

                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );






        },

        deleteRow: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.$swal
                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare l'elemento selezionato ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_alertduedate.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_alertduedate.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });

                        console.log("IDS SEL:", ids);


                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apiprocessi.deleteProcesso(
                            ids,
                            v_token
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteProcesso", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati eliminati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();

                                window.table.ajax.reload();

                                that.hideShowButtons(false);


                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },

    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
.cont_schedtool_processi .selfiltri {
    max-width: 27px;
    min-height: 27px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 27px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}


.cl_combo1 {
    margin-left: -41px !important;
    min-width: 70px !important;
}



.clGoogleDrive {
    transform: translateX(-525px);
}


.styleDateTimeHistory {
    font-size: 8.5px;
    font-weight: bold;
    width: 114px;
}

.styleFileNameHistory {
    margin-left: 10px;
    padding-bottom: 5px;
    width: 232px;
    font-size: 8.5px;
    font-weight: bold;
}

.dataTable tbody tr,
.dataTable tbody tr td {
    vertical-align: middle;
    padding: 6px !important;
}

.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #f5cf2e;

}

.pay a {
    color: #f5cf2e;
    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #f5cf2e;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}

.table-responsive {
    max-width: 100vw;
}

ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    /* border-radius: 12px; */
    /* min-height: 12px; */
    /* transform: translateY(-2px); */
    background-image: url(/smartb/img/FiltroNucleo.png);
    /* padding: 1px 11px; */
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);
}


.cont-table .spin {

    display: flex;
    position: absolute;
    z-index: 100;
    min-width: 100vw;
    min-height: 100vh;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
}

.cont_schedtool_processi {
    overflow-x: auto !important;
}

.cont_schedtool_processi #myTable {
    margin-right: 3px;
    width: 985px !important;
}

.cont_schedtool_processi table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_schedtool_processi table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 25px !important;
}

.cont_schedtool_processi th {
    padding-left: 5px !important;
}

.cont_schedtool_processi ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_schedtool_processi .table-responsive {
    padding: 0px;
    min-width: 80vw;
    max-width: 96vw;
}


.cont_schedtool_processi #myTable_filter {
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    background-color: white;
    border-color: #45b7e9;

}

.cont_schedtool_processi div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.cont_schedtool_processi #myTable_wrapper {
    min-width: initial !important;
    min-width: 1000px !important;
    max-width: 1000px !important;
    width: 1000px !important;
}

.cont_schedtool_processi .text_title {
    background-color: white;
    color: black;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
    font-size: 10px;
    min-width: 2vw
}


.cont_schedtool_processi .cl_active {
    display: inline-flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
}


.cont_schedtool_processi .selazienda {
    max-width: 23px;
    min-height: 23px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}



.cont_schedtool_processi .cl_checkall {
    padding-left: 1px;
    padding-top: 2px;
}

.cont_schedtool_processi .checkall {
    width: 13px;
    height: 13px;
}


.cont_schedtool_processi .val_status {
    width: 10px !important;
}

.cont_schedtool_processi .selricerca {
    max-width: 31px;
    min-height: 31px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-right: 14px !important;
}

.cont_schedtool_processi .selaggiorna {
    max-width: 23px;
    min-height: 23px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 23px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}



.cont_schedtool_processi .cont_buttons {
    display: flex;
    align-items: center;
    float: left;
    min-width: 86px;
    padding-left: 10px;
    justify-content: inherit;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.itemAzienda {
    margin-left: 15px;
}

.seltipologia {
    max-width: 25px;
    min-height: 25px;
    margin-left: 13px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.aggiorna {
    max-width: 25px;
    min-height: 25px;
    margin-left: 13px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.font-row-grid-datetime {
    font-size: 10px !important;
    padding: 5px !important;
    width: 100px !important;
}

.font-row-grid-datetime-mobile {
    font-size: 10px !important;
    padding: 5px !important;
    width: 85px !important;
}

.cl-date {
    font-size: 10px;
    color: gray;
}


@media screen and (max-width: 960px) {

    .cont_schedtool_processi .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_schedtool_processi #myTable {
        /* margin-top:54px; */
    }

    .cont_schedtool_processi #myTable_filter {
        top: 56px;
        position: fixed;

    }

}

@media screen and (max-width: 1024px) {

    .contIcon {
        display: inherit;
    }



    .cont_schedtool_processi #myTable {
        max-width: 99vw !important;
        margin-right: 0px;
        min-width: 99vw !important;
        position: relative;
        width: 99vw !important;

    }

    .cont_schedtool_processi #myTable_filter {
        top: 56px;
        position: fixed;

    }

    .cont_schedtool_processi .table-responsive {
        padding: 0px;
        min-width: 95vw !important;
        max-width: 95vw !important;
        left: 0px;
        position: fixed;

    }

    .cont_schedtool_processi #myTable_wrapper {
        min-width: initial;
        min-width: 99vw !important;
        max-width: 99vw !important;
        width: 99vw !important;
        overflow-x: hidden !important;
    }

    .cont_schedtool_processi .cl_num {
        margin-left: -2px !important;
    }


    .cont_schedtool_processi .cl_checkall {
        padding-left: 20px;
        padding-top: 2px;
    }



}
</style>
